import React from 'react';
import PromoBanner from '~/App/shared/components/Promo/PromoBanner';
import PromoCardAdditional from '~/App/shared/components/Promo/PromoCardAdditional';
import PromoCardCampaign from '~/App/shared/components/Promo/PromoCardCampaign';
import PromoCardTrustPilot from '~/App/shared/components/Promo/PromoCardTrustPilot';
import { PromoCardTemplateData } from '~/App/shared/interfaces/PromoSettingCMSData';

export type PromoCardSwitchProps = {
  template: PromoCardTemplateData;
  isLoading?: boolean;
};

export const PromoCardSwitch = ({
  template,
  isLoading = false
}: PromoCardSwitchProps) => {
  const { cardType } = template;

  switch (cardType) {
    case 'CAMPAIGN':
      return <PromoCardCampaign {...{ ...template, isLoading }} />;
    case 'TRUSTPILOT':
      return <PromoCardTrustPilot {...{ ...template, isLoading }} />;
    case 'ADDITIONAL':
      return <PromoCardAdditional {...{ ...template, isLoading }} />;
    case 'BANNER':
      return <PromoBanner {...{ ...template, isLoading }} />;
    default:
      return null;
  }
};
