import { DataHookFunctionProps } from '~/helpers/provideDataHook';
import {
  fetchFacilities,
  fetchStoreObjects
} from '~/App/shared/actions/storeObjectActions';
import { deserializationFilters } from '~/helpers/storeObjects';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { isMainCategory } from '~/helpers/filterSearchParams';
import { FilterPageProps } from '.';
import { filteredEntitiesSelector } from '~/App/shared/selectors/storeObjectsSelectors';
import { isClient } from '~/config/public/environment';

export const objectsFilterDataHook =
  ({ mainCategory }: { mainCategory?: MainCategory }) =>
  async (props: DataHookFunctionProps) => {
    const { getState, location, dispatch } = props;
    const { search = '' } = location ?? {};

    const searchParams = new URLSearchParams(search);

    if (isMainCategory(mainCategory)) {
      mainCategory && searchParams.set('vehicleType', mainCategory);
    }

    if (!searchParams.has('orderBy')) {
      searchParams.append('orderBy', '-grade');
    }

    const searchString = searchParams.toString();

    const cachedEntities = filteredEntitiesSelector(searchString)(getState());

    // should always call fetchFacilities before cachedEntities checking
    await dispatch(fetchFacilities());

    if (cachedEntities.length > 0 || isClient) {
      return {
        skipInitialFetch: false, // make sure to fetch new data after navigating to cached page
        mainCategory
      } as FilterPageProps;
    }

    await dispatch(
      fetchStoreObjects({
        filters: deserializationFilters(searchString)
      })
    );

    const serverError = getState().storeObjects.error;

    // server error
    if (serverError) {
      return {
        skipInitialFetch: false,
        mainCategory
      } as FilterPageProps;
    }

    // normal success
    return {
      skipInitialFetch: true,
      mainCategory,
      ...props
    } as FilterPageProps;
  };
