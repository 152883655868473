import React from 'react';

import { useTranslation } from '~/Locale';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { FilterChipList } from './FilterChipList';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { getMinMaxIntervalValues } from '../helpers';

type Props = {
  searchQuery: string;
  mainCategory?: MainCategory;
};

export const SelectedFilters = ({ searchQuery, mainCategory }: Props) => {
  const { t } = useTranslation();
  const [, setSearchParam, setSearchParams] = useSearchParams();

  const handleRemoveChip = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setSearchParams(
        key.reduce((acc, key) => ({ ...acc, [key]: undefined }), {})
      );

      return;
    }

    setSearchParam(key, undefined);
  };

  const minMaxIntervalValues = getMinMaxIntervalValues(mainCategory);

  return (
    <div>
      {t('Filters: ')}
      <FilterChipList
        searchQuery={searchQuery}
        onRemoveChip={handleRemoveChip}
        minMaxIntervalValues={minMaxIntervalValues}
      />
    </div>
  );
};
