import React, { useCallback, useState } from 'react';
import { BodyLink } from '@kvdbil/components';
import { OnChangeValue, ActionMeta, Options } from 'react-select';
import styled from 'styled-components';
import { SliderOptionType } from '../types';
import { SliderWrapper, Slider } from '~/App/shared/components/Slider';
import { trackGA4ProductFilter } from '~/helpers/client/ga4TrackEvent';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

const SliderOptionContainer = styled.div`
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const SliderDisplay = styled.div`
  text-align: center;
  text-transform: lowercase;
`;

interface SliderOptionProps extends SliderOptionType {
  hideLabel: boolean;
  onChange(
    newValue: OnChangeValue<SliderOptionType, true>,
    actionMeta: ActionMeta<SliderOptionType>
  ): void;
  getSelectValue?(): Options<SliderOptionType>;
  selectedMainCategory: MainCategory;
}

export const SliderOption = ({
  hideLabel,
  value: initialValue,
  searchParamKey,
  label,
  min,
  max,
  valueSuffix,
  step,
  onChange,
  getSelectValue,
  displayedValueFormatter,
  selectedMainCategory
}: SliderOptionProps) => {
  const [optionValue, setOptionValue] =
    useState<SliderOptionType['value']>(initialValue);
  const { ga4User, isHydrated } = useGA4UserIsHydrated();

  const handleOnChange = useCallback(
    ([from, to]: SliderOptionType['value']) => {
      setOptionValue([from, to]);
    },
    []
  );

  const handleAfterChange = useCallback(
    (values: SliderOptionType['value']) => {
      setOptionValue(values);

      const selectValue = (getSelectValue && getSelectValue()) || [];
      const newSelectValue = selectValue.filter(
        ({ label: optionLabel }) => optionLabel !== label
      );
      newSelectValue.push({
        label,
        value: values,
        searchParamKey,
        max,
        min,
        step,
        selectedMainCategory
      });

      onChange(newSelectValue, {
        action: 'select-option',
        option: newSelectValue[0]
      });

      if (
        isHydrated &&
        values?.length === 2 &&
        !(values[0] === min && values[1] === max) &&
        searchParamKey
      ) {
        trackGA4ProductFilter(
          {
            filter_group: searchParamKey,
            filter_value: `${values[0]}-${values[1]}`,
            filter_page: selectedMainCategory
          },
          ga4User
        );
      }
    },
    [
      searchParamKey,
      getSelectValue,
      label,
      max,
      min,
      onChange,
      step,
      ga4User,
      isHydrated,
      selectedMainCategory
    ]
  );

  const displayedValues = displayedValueFormatter
    ? displayedValueFormatter(optionValue)
    : optionValue;

  return (
    <SliderOptionContainer key={label}>
      {!hideLabel && <BodyLink>{label}</BodyLink>}
      <SliderDisplay>
        {displayedValues[0]} - {displayedValues[1]} {valueSuffix}
      </SliderDisplay>
      <SliderWrapper>
        <Slider
          range
          min={min}
          max={max}
          step={step}
          value={[Number(optionValue[0]) - 0, Number(optionValue[1]) - 0]}
          onChange={handleOnChange}
          // we need to do our onw onAfterChange if this is deprecated...
          onAfterChange={handleAfterChange}
        />
      </SliderWrapper>
    </SliderOptionContainer>
  );
};
