import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import { MultiFilterSelectProps, OptionType } from '../types';

import { useSearchParams } from '~/App/shared/hooks/useSearchParams';

export const useOtherFilterSelect = (
  props: MultiFilterSelectProps
): MultiFilterSelectProps => {
  const { options } = props;
  const [searchParams, , setSearchParams] = useSearchParams();

  // find all active filters from searchParams and options
  const activeValue = useMemo(() => {
    const activeFilters = options?.filter((option: OptionType) => {
      return option.searchParamKey && searchParams?.get(option.searchParamKey);
    });

    return activeFilters
      ?.map((option: OptionType) => option.searchParamKey)
      .filter(Boolean) as string[];
  }, [searchParams, options]);

  const handleChange = useCallback(
    (valueOptions: OptionType[]) => {
      // find all values that are removed from current activeValue
      const valuesRemoved = activeValue?.filter(
        v =>
          !valueOptions
            ?.map((option: OptionType) => option.searchParamKey)
            .includes(v)
      );

      // find all values that are added to current activeValue
      const valuesAdded = valueOptions
        ?.filter(
          o => o.searchParamKey && !activeValue?.includes(o.searchParamKey)
        )
        .map(o => o.searchParamKey);

      const data = options?.reduce((acc, option: OptionType) => {
        // Removing filters by setting them to empty string
        if (
          option.searchParamKey &&
          valuesRemoved?.includes(option.searchParamKey)
        ) {
          acc[option.searchParamKey] = '';
          return acc;
        }

        // Adding filters by setting them to true
        if (
          option.searchParamKey &&
          valuesAdded?.includes(option.searchParamKey)
        ) {
          switch (option.searchParamKey) {
            case 'countdownDate':
              const date = dayjs().format('YYYY-MM-DD');
              acc[option.searchParamKey] = date;
              break;
            case 'equipment':
              acc[option.searchParamKey] = option.value;
              break;
            default:
              acc[option.searchParamKey] = 'true';
          }
        }

        return acc;
      }, {} as Record<string, string>);

      setSearchParams(data);
    },
    [activeValue, options, setSearchParams]
  );

  return {
    ...props,
    options,
    onChange: handleChange,
    activeValue: activeValue
  };
};
