import { Button, Flex, Title, mq } from '@kvdbil/components';
import React, { useState } from 'react';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import styled, { css } from 'styled-components';
import { FilterPageData } from '~/App/shared/interfaces/store/CmsData';
import { useTranslation } from '~/Locale';

type StyledStructuredTextProps = {
  expanded?: boolean;
};

const StyledStructuredText = styled.div<StyledStructuredTextProps>`
  margin-bottom: 0.5rem;

  & > div > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;

    ${({ expanded }) =>
      !expanded &&
      css`
        overflow: hidden;

        ${mq(null, 'tablet')} {
          -webkit-line-clamp: 3;
        }
        ${mq('tablet')} {
          -webkit-line-clamp: 2;
        }
      `}
  }
`;

const StyledButton = styled(Button)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

interface Props {
  title: FilterPageData['title'];
  description: FilterPageData['description'];
}

const TopContent = ({ title, description }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    setExpanded(pre => !pre);
  };

  return (
    <>
      <Flex
        gap={{
          mobileS: '0',
          tablet: '1rem'
        }}
        justify={'start'}
        align={'center'}
        direction={{
          mobileS: 'column',
          tablet: 'row'
        }}
      >
        <Title as="h1">{title}</Title>

        <StyledButton onClick={handleExpanded} variant="flat" color="info">
          {!expanded ? t('More info') : t('Less info')}
        </StyledButton>
      </Flex>

      <StyledStructuredText expanded={expanded}>
        <StructuredText data={description} />
      </StyledStructuredText>
    </>
  );
};

export default TopContent;
