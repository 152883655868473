import { useCallback } from 'react';
import { SliderFilterSelectProps, SliderType } from '../types';
import { formatMoneyWithoutCurrency } from '~/helpers/currency';
import { useSliderSelectData } from '../SliderFilter/useSliderSelectData';
import { MinMaxIntervalKey } from '~/config/constants';

interface GeneralSliderFilterProps extends SliderFilterSelectProps {
  step: number;
  label: string;
  valueSuffix: string;
  displayedValueFormatter?: (value: SliderType) => [string, string];
  minMaxValue?: SliderType;
}

export const useGeneralSliderFilterSelect = (
  props: GeneralSliderFilterProps
): SliderFilterSelectProps => {
  const {
    name,
    label,
    step,
    valueSuffix,
    displayedValueFormatter,
    minMaxValue
  } = props;

  const valueFormatter = useCallback(
    ([fromValue, toValue]: SliderType): [string, string] => {
      if (displayedValueFormatter) {
        return displayedValueFormatter([fromValue, toValue]);
      }

      return [
        formatMoneyWithoutCurrency(fromValue),
        formatMoneyWithoutCurrency(toValue)
      ];
    },
    [displayedValueFormatter]
  );

  return useSliderSelectData(
    name as MinMaxIntervalKey,
    label,
    step,
    props.selectedMainCategory,
    valueSuffix,
    valueFormatter,
    minMaxValue
  );
};
