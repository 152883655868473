import { getPathFromMainCategory } from './filterTranslation';
import { TranslateFunction } from '~/Locale';

type RedirectResult = {
  redirect: boolean;
  url: string;
  pathname: string;
  search: string;
};

export const shouldRedirect = (
  t: TranslateFunction,
  url: string
): RedirectResult => {
  const pathUrl = `/${url}`.replace(/\/+/g, '/').trim();
  const dummyUrl = new URL(`http://dummy.com${pathUrl}`);
  const pathname = dummyUrl.pathname;
  const urlParams = dummyUrl.searchParams;

  const vehicleType = urlParams.get('vehicleType');
  const redirectUrl = getPathFromMainCategory(t, vehicleType);

  // Remove vehicleType from URL
  urlParams.delete('vehicleType');
  const searchString = urlParams.toString() ? `?${urlParams.toString()}` : '';

  // If the redirect URL is the same as the current URL, don't redirect
  if (!redirectUrl || pathname.includes(redirectUrl) || !vehicleType) {
    return {
      redirect: false,
      url: `${pathname}${searchString}`,
      pathname: pathname,
      search: searchString || ''
    };
  }

  // Otherwise, redirect to the new URL
  return {
    redirect: true,
    url: `${redirectUrl}${searchString}`,
    pathname: redirectUrl,
    search: searchString || ''
  };
};
