import React from 'react';
import { Flex } from '@kvdbil/components';
import SaveSearchDialog from '../../ObjectPage/components/SaveSearchDialog';
import { ClearSearchButton } from './ClearSearchButton';
import { useSaveSearch, SaveSearchButton } from './SaveSearchButton';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

type Props = {
  searchQuery: string;
  mainCategory?: MainCategory;
};

export function FilterControls({ searchQuery, mainCategory }: Props) {
  const {
    onClickHandler,
    isLoading,
    isDisabled,
    isSaved,
    dialogIsOpen,
    closeDialog
  } = useSaveSearch(searchQuery);

  if (isDisabled) {
    return null;
  }

  return (
    <div>
      <Flex
        basis={1}
        gap={{
          mobileS: '1rem',
          tablet: '1rem'
        }}
        justify={{
          mobileS: 'space-between',
          tablet: 'flex-end'
        }}
      >
        <ClearSearchButton />
        <SaveSearchButton
          onClick={onClickHandler}
          isSaved={isSaved}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
      {!isDisabled && dialogIsOpen && (
        <SaveSearchDialog
          handleClose={closeDialog}
          searchQuery={searchQuery}
          mainCategory={mainCategory}
        />
      )}
    </div>
  );
}
