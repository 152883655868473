import React from 'react';
import { isGroupedOptionsType, isOptionsType } from '../helper';

import { MultiFilterSelectProps } from '../types';
import CollapseFilterSelect from '../Shared/CollapseFilterSelect';
import { MultiMobileFilterItem } from './MultiMobileFilterItem';
import { useMultiFilterSelect } from './useMultiFilterSelect';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';

const NoOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
`;

export const MultiMobileFilterSelect = (props: MultiFilterSelectProps) => {
  const { t } = useTranslation();

  const { options, placeholder, name } = props;

  const { values, isOpen, setIsOpen, handleChange } =
    useMultiFilterSelect(props);

  return (
    <CollapseFilterSelect
      isOpen={isOpen}
      label={placeholder ?? ''}
      onClick={() => setIsOpen(pre => !pre)}
    >
      {options &&
        isGroupedOptionsType(options) &&
        options?.map((groupe, index) => (
          <div key={index}>
            <div>{groupe.label}</div>
            {groupe.options.map((option, index) => (
              <MultiMobileFilterItem
                key={index}
                onChange={handleChange}
                option={option}
                values={values}
                name={name}
              />
            ))}
          </div>
        ))}

      {options &&
        isOptionsType(options) &&
        options.map((option, index) => (
          <MultiMobileFilterItem
            key={index}
            onChange={handleChange}
            option={option}
            values={values}
            name={name}
          />
        ))}

      {!options.length && <NoOptions>{t('No options')}</NoOptions>}
    </CollapseFilterSelect>
  );
};
