import React from 'react';
import { SliderFilterSelectProps, SliderOptionType } from '../types';
import CollapseFilterSelect from '../Shared/CollapseFilterSelect';
import { useSliderFilterSelect } from './useSliderFilterSelect';
import { SliderOption } from './SliderOption';

export const SliderMobileFilterSelect = (props: SliderFilterSelectProps) => {
  const { options, placeholder, label } = props;

  const { isOpen, setIsOpen, handleChange } = useSliderFilterSelect(props);

  return (
    <CollapseFilterSelect
      isOpen={isOpen}
      onClick={() => setIsOpen(pre => !pre)}
      label={placeholder ?? label ?? ''}
    >
      {options?.map((option: SliderOptionType) => (
        <SliderOption
          {...option}
          key={option.label}
          hideLabel={options.length === 1}
          onChange={handleChange}
          selectedMainCategory={props.selectedMainCategory}
        />
      ))}
    </CollapseFilterSelect>
  );
};
