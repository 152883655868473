import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  savedSearchFromSearchParams,
  savedSearchesIsLoading as savedSearchesIsLoadingSelector
} from '~/App/shared/selectors/savedSearchesSelector';
import {
  removeSavedSearch,
  saveSearch
} from '~/App/shared/actions/saved-searches';

import { useTranslation } from '~/Locale';
import { useHistory } from 'react-router';
import { isLoggedInSelector } from '~/App/shared/selectors/sessionSelector';
import { SavedSearch } from '~/App/shared/interfaces/SavedSearch';
import {
  ButtonRegularText,
  CheckIcon,
  NotificationIcon
} from '@kvdbil/components';
import { StyledButton } from './StyledButton';
import styled from 'styled-components';
import { useFilterSearchParams } from '../hooks/useFilterSearchParams';
import { convertFilterSearchParamsToSearchQuery } from '~/helpers/searches';

export const StyledButtonText = styled(ButtonRegularText)`
  font-weight: 400;
`;

const getPrefixIcon = (isSaved: boolean, isLoading?: boolean) => {
  if (isLoading) {
    return undefined;
  }

  return isSaved ? (
    <CheckIcon data-testid="check-icon" />
  ) : (
    <NotificationIcon data-testid="notification-icon" />
  );
};

export const SaveSearchButton = ({
  onClick,
  isSaved,
  isLoading,
  isDisabled
}: {
  isSaved: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledButton
      onClick={onClick}
      variant="outline"
      color="neutral"
      size="regular"
      disabled={isLoading}
      prefixIcon={getPrefixIcon(isSaved, isLoading)}
      isLoading={isLoading}
      isDisabled={isDisabled || isLoading}
      data-testid="save-search-button"
    >
      <StyledButtonText>{isSaved ? t('Watched') : t('Watch')}</StyledButtonText>
    </StyledButton>
  );
};

export const useSaveSearch = (
  searchQuery: string,
  isDisabled = false
): {
  onClickHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isLoading: boolean;
  isDisabled: boolean;
  isSaved: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  dialogIsOpen: boolean;
} => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { filterSearchParams } = useFilterSearchParams([], searchQuery);

  const isLoggedIn = useSelector(isLoggedInSelector);

  const search = convertFilterSearchParamsToSearchQuery(
    filterSearchParams
  ) as SavedSearch;
  const savedSearch = useSelector(
    savedSearchFromSearchParams(filterSearchParams.toString())
  );

  const isLoading = useSelector(savedSearchesIsLoadingSelector);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const save = useCallback(() => {
    if (isLoading) {
      return;
    }

    dispatch(saveSearch(t, search, '', history.push, false));
  }, [dispatch, history?.push, isLoading, search, t]);

  const remove = useCallback(() => {
    if (isLoading) {
      return;
    }
    savedSearch && dispatch(removeSavedSearch(savedSearch, isLoggedIn));
  }, [dispatch, isLoggedIn, savedSearch, isLoading]);

  const onClickHandler = () => {
    if (!dialogIsOpen && !isLoggedIn) {
      openDialog();
      return;
    }

    if (savedSearch) {
      remove();
      return;
    }

    save();
  };

  const openDialog = () => {
    setDialogIsOpen(true);
  };
  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  return {
    onClickHandler,
    isLoading,
    isSaved: Boolean(savedSearch),
    isDisabled,
    closeDialog,
    openDialog,
    dialogIsOpen
  };
};
