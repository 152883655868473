import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Headline, NextIcon, Subtitle, mq } from '@kvdbil/components';

import { PromoCardBaseTemplate } from '~/App/shared/interfaces/PromoSettingCMSData';
import { ColorField } from '~/config/generated/graphql';
import { Nullable } from '../../types/Nullable';
import { CardContent } from './CardContent';
import { useGA4User } from '../../hooks/useGA4User';
import { trackGA4FilterContentCard } from '~/helpers/client/ga4TrackEvent';
import Link from '~/App/shared/components/Link';

interface ContainerProps {
  $backgroundColor: Nullable<ColorField>;
  $color: Nullable<ColorField>;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  height: 429px;
  padding: 3.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  box-shadow: ${({ theme }) => theme.elevations.elevation2};
  border-radius: 0.5rem;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor.hex};
    `};

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color.hex};
    `};

  ${mq('tablet')} {
    padding: 5rem 1rem 1rem;
    height: 100%;
    justify-content: start;
    align-items: center;
    align-self: stretch;
    gap: 0;
  }
`;

const Heading = styled(Headline)`
  color: inherit;
  padding: 0.5rem 0;
  width: 100%;
  border: ${({ theme }) => theme.colors.text.light} 2px solid;

  text-align: center;

  ${mq('tablet')} {
    margin-bottom: 2rem;
    border-left: none;
    border-right: none;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;

  ${mq('tablet')} {
    flex: 1;
    justify-content: center;
    align-items: start;
    gap: 2rem;
  }
`;

const MobileSubtitleWithDesktopTinyTitle = styled(Subtitle)`
  ${mq('tablet')} {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

const ReadMore = styled.span`
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeRegularWeight};
  font-size: 1.125rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }

  ${mq('tablet')} {
    font-family: ${({ theme }) => theme.typography.fontBaseFamily};
    font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export type PromoCardCampaign = PromoCardBaseTemplate;

const PromoCardCampaign = ({
  title,
  backgroundColor,
  textColor,
  link,
  content,
  ga4EventIdentifier
}: PromoCardCampaign) => {
  const ga4User = useGA4User();

  const handleOnClick = useCallback(() => {
    trackGA4FilterContentCard({ card_type: ga4EventIdentifier }, ga4User);
  }, [ga4EventIdentifier, ga4User]);

  return (
    <Link to={link?.url}>
      <Container
        $backgroundColor={backgroundColor}
        $color={textColor}
        data-testid="promo-card-campaign"
        onClick={handleOnClick}
      >
        {title && <Heading>{title}</Heading>}
        <DescriptionContainer>
          {content && (
            <CardContent
              data={content}
              textAs={MobileSubtitleWithDesktopTinyTitle}
            />
          )}
        </DescriptionContainer>

        {link && (
          <ReadMore>
            {link.text}
            <NextIcon />
          </ReadMore>
        )}
      </Container>
    </Link>
  );
};

export default PromoCardCampaign;
