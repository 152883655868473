import { useEffect } from 'react';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';
import { trackGA4CategoryView } from '~/helpers/client/ga4TrackEvent';
import { useLocation } from 'react-router';
import { useDebounceGA4Event } from '~/App/shared/hooks/useDebounceGA4Event';

export const useTrackCategoryView = () => {
  const location = useLocation();

  const { ga4User, isHydrated } = useGA4UserIsHydrated();
  const ga4ViewEvent = useDebounceGA4Event();

  useEffect(() => {
    const trackCategoryView = () => {
      const queryParams = new URLSearchParams(location.search);

      const auctionType = queryParams.get('auctionType');

      /* query params to ignore */
      queryParams.delete('orderBy');
      queryParams.delete('terms');
      queryParams.delete('auctionType');

      if (
        Array.from(queryParams).length > 0 &&
        (auctionType === 'BIDDING' || auctionType === 'BUY_NOW')
      ) {
        trackGA4CategoryView(
          {
            category_type: auctionType == 'BIDDING' ? 'auction' : 'fixed_price'
          },
          ga4User
        );
      }
    };

    /* session must be hydrated before tracking and page_view has to been pushed */
    if (isHydrated) {
      ga4ViewEvent(trackCategoryView);
    }
  }, [ga4User, ga4ViewEvent, isHydrated, location.search]);
};
