import React from 'react';
import { mq } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import styled from 'styled-components';
import { SortingSelect } from '~/App/views/FilterPage/components/SortingSelect';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  ${mq('tablet')} {
    justify-content: end;
    gap: 2.5rem;
  }
`;

type Props = {
  hits: number;
};

export const HitAndSorting = ({ hits }: Props) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="count">
      {t('%d pcs', hits)}
      <SortingSelect />
    </Container>
  );
};
