import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  PromoCardBaseTemplate,
  StampData
} from '../../interfaces/PromoSettingCMSData';
import {
  mq,
  Stack,
  Stamp,
  TinyTitleBold,
  Button,
  Caption
} from '@kvdbil/components';
import { locale } from '~/config/public/environment';
import Link from '~/App/shared/components/Link';
import { useGA4User } from '../../hooks/useGA4User';
import { trackGA4FilterContentCard } from '~/helpers/client/ga4TrackEvent';
import { CardContent } from './CardContent';
import { ColorField, StampRecord } from '~/config/generated/graphql';
import { Nullable } from '../../types/Nullable';
import { isCustomRecordArray } from '~/helpers/cmsDatoUtils';
import { CustomRecord, StructuredTextData } from '../../interfaces/datoCMS';
import { getSvOrEnLocale } from '~/App/shared/localization/helpers';

const Title = TinyTitleBold;
const Description = Caption;

interface ContainerProps {
  $backgroundColor: Nullable<ColorField>;
  $color: Nullable<ColorField>;
}

const Container = styled(Stack)<ContainerProps>`
  flex: 1;
  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor?.hex ?? theme.colors.background.sand};

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color.hex};
    `};
  padding: 2rem 1rem;
  box-shadow: ${({ theme }) => theme.elevations.elevation2};
  border-radius: 0.5rem;

  ${mq('tablet')} {
    box-shadow: none;
    border-radius: 0;
  }

  ${mq('laptop')} {
    padding: 1rem;
  }
`;

const StampWrapper = styled.div`
  justify-content: center;
  display: flex;
  ${mq('laptop')} {
    svg {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
`;

const TextContainer = styled.div`
  max-width: 18.5rem;
  text-align: center;
`;

const StyledLink = styled(Link)`
  width: 100%;
  max-width: 25rem;

  ${mq('laptop')} {
    width: auto;
  }
`;

type Props = PromoCardBaseTemplate & {
  title?: string;
  isLoading?: boolean;
};

const PromoBanner = ({
  title,
  content,
  backgroundColor,
  textColor,
  link,
  ga4EventIdentifier,

  isLoading = false
}: Props) => {
  const ga4User = useGA4User();

  const handleOnClick = useCallback(() => {
    trackGA4FilterContentCard({ card_type: ga4EventIdentifier }, ga4User);
  }, [ga4EventIdentifier, ga4User]);

  const { contentData, stampData } = useBannerData(content);

  return (
    <Container
      $backgroundColor={backgroundColor}
      $color={textColor}
      spacing={2}
      direction={{
        mobileS: 'column',
        tablet: 'row'
      }}
      justify={'center'}
      align={'center'}
      data-testid="promo-card-banner"
    >
      <StampWrapper>
        <Stamp
          type={stampData.stampType}
          color={stampData.stampColor}
          appearance={stampData.stampAppearance}
          locale={getSvOrEnLocale(locale)}
          size={stampData.stampSize}
        />
      </StampWrapper>

      {title && (
        <TextContainer>
          {title && <Title as="h3">{title}</Title>}
          {content && <CardContent textAs={Description} data={contentData} />}
        </TextContainer>
      )}

      {link?.url && (
        <StyledLink to={link.url} onClick={handleOnClick}>
          <Button
            as="span"
            color="info"
            size="regular"
            variant="outline"
            isLoading={isLoading}
          >
            {link.text}
          </Button>
        </StyledLink>
      )}
    </Container>
  );
};

export default PromoBanner;

const useBannerData = (
  content: Nullable<StructuredTextData> = {
    blocks: [],
    value: {
      document: {
        children: []
      }
    }
  }
) => {
  const stampData = useMemo(() => {
    const blocks = content?.blocks ?? [];

    if (isCustomRecordArray(blocks)) {
      const foundStamp = blocks.find((block: CustomRecord) => {
        return block.__typename === 'StampRecord';
      });

      if (foundStamp) {
        return {
          stampType: (foundStamp as StampRecord).stampType,
          stampColor: (foundStamp as StampRecord).stampColor,
          stampAppearance: (foundStamp as StampRecord).stampAppearance,
          stampSize: (foundStamp as StampRecord).stampSize
        } as StampData;
      }
    }

    return {
      stampType: 'carguide',
      stampColor: 'info',
      stampAppearance: 'solid',
      stampSize: 120
    } as StampData;
  }, [content?.blocks]);

  // Filters away stamp from content
  const contentData = useMemo(() => {
    const blocks = content?.blocks ?? [];

    if (!isCustomRecordArray(blocks)) {
      return {
        blocks: content?.blocks,
        value: content?.value
      };
    }

    // find all stamp block ids
    const stampBlockIds = blocks
      ?.filter((block: CustomRecord) => block.__typename === 'StampRecord')
      .map((block: CustomRecord) => block?.id);

    // find and remove stamp from blocks
    const contentBlocks = blocks?.filter(
      (block: CustomRecord) => stampBlockIds?.includes(block.id) !== true
    );

    // find and remove stamp from value.document.children
    const contentChildren = content?.value.document.children.filter(
      ({ item }: { item: string }) => stampBlockIds?.includes(item) !== true
    );

    return {
      blocks: contentBlocks,
      value: {
        ...content?.value,
        document: {
          ...content?.value?.document,
          children: contentChildren
        }
      }
    };
  }, [content?.blocks, content?.value]);

  return {
    stampData,
    contentData: { ...content, ...contentData }
  };
};
