import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';
import { SavedSearches } from '../interfaces/store/SavedSearches';
import { reSerializeFilters } from '~/helpers/storeObjects';

export const searchRoot = createSelector<
  ReduxStore,
  SavedSearches,
  SavedSearches
>(
  state => state?.savedSearches,
  value => value
);

export const savedSearchesIsLoading = createSelector(
  searchRoot,
  state => state?.isLoading
);

export const savedSearchesLoadingId = createSelector<
  ReduxStore,
  SavedSearches,
  string
>(searchRoot, state => state?.loadingId || '');

export const savedSearchFromSearchParams = (param: string) =>
  createSelector(searchRoot, root => {
    const searchParams = reSerializeFilters(param, ['auctionType', 'orderBy']);

    return root?.list?.find(search => {
      // this will drop unwanted params and reorder the params in string
      const listParams = reSerializeFilters(search.queryString);

      return listParams.toString() === searchParams.toString();
    });
  });
