import { Stack } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import { CardListData } from './interfaces';
import { environment } from '~/config/public';

export const recalculateIndex = (card: CardListData, index: number) => {
  // if not a promo card, recalculate the index
  if (!('template' in card.data)) {
    return {
      ...card,
      index
    };
  }

  return card;
};

const getColor = (index: number, match: boolean) => {
  if (index % 10 === 0) {
    return 'rgba(212, 212, 212, 0.9)';
  }

  if (!match) {
    return 'rgba(233, 36, 36, 0.5)';
  }

  return 'rgba(25, 192, 25, 0.5)';
};

const stops = (match: boolean) => {
  const stops = [];
  for (let i = 0; i <= 100; i += 5) {
    const color = getColor(i, match);
    stops.push(`${color} ${i}%`);
  }
  return stops;
};

const StyledDebug = styled.div<{ match: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  justify-items: start;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;

  background-image: ${({ match }) =>
    `linear-gradient(45deg, ${stops(match).join(', ')})`};

  pointer-events: none;
`;

const StyledDebugInfo = styled.div<{ match: boolean }>`
  padding: 0.3rem 0.5rem;
`;

const StyledDebugInfoText = styled.small`
  font-size: 0.7rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
`;

/**
 * @deprecated NEVER USE THIS IN PRODUCTION!
 */
export const CardIndexDebugger = ({
  currentIndex,
  wantedIndex
}: {
  currentIndex: number;
  wantedIndex: number;
}) => {
  // makes sure the debugger is not shown in production
  if (environment.isProduction) {
    return null;
  }

  return (
    <StyledDebug match={currentIndex === wantedIndex}>
      <StyledDebugInfo match={currentIndex === wantedIndex}>
        <Stack spacing={0.01}>
          <StyledDebugInfoText>current: {currentIndex}</StyledDebugInfoText>
          <StyledDebugInfoText>wanted: {wantedIndex}</StyledDebugInfoText>
        </Stack>
      </StyledDebugInfo>
    </StyledDebug>
  );
};
