import { GroupBase, Options, OptionsOrGroups } from 'react-select';
import { OptionType } from '~/App/shared/types/OptionType';

export const reMapValues = <T extends OptionType = OptionType>(
  values: string[],
  options: OptionsOrGroups<T, GroupBase<T>>
) => {
  if (!options) return [];
  if (options.length < 1) return [];

  // // if is GroupedOptionsType
  if (isGroupedOptionsType(options)) {
    const unGroupedOptions = options.flatMap(group => group.options);
    const reMappedValues = unGroupedOptions.filter(group => {
      const isValueInGroup = group && values?.includes(group.value);
      if (isValueInGroup) {
        return group;
      }
      return undefined;
    });
    return reMappedValues;
  }

  // if is OptionsType
  if (Array.isArray(options)) {
    const reMappedValues = options.filter(
      option =>
        option &&
        values.includes(option.value === 'towbar' ? 'equipment' : option.value)
    );

    return reMappedValues;
  }

  return [];
};

export const reMapValue = <T extends OptionType = OptionType>(
  value: string,
  options?: OptionsOrGroups<T, GroupBase<T>>
): T | null => {
  if (!options) return null;
  if (options.length < 1) return null;

  // if is GroupedOptionsType
  if (isGroupedOptionsType<T>(options)) {
    const unGroupedOptions = options.flatMap(group => group.options);
    return unGroupedOptions.find(option => option.value === value) ?? null;
  }

  // if is OptionsType
  if (isOptionsType(options)) {
    return options.find(option => option.value === value) ?? null;
  }

  return null;
};

export const isGroupedOptionsType = <T extends OptionType = OptionType>(
  options: readonly (T | GroupBase<T>)[]
): options is readonly GroupBase<T>[] =>
  (Array.isArray(options) && options[0]?.options) ?? false;

export const isOptionsType = <T extends OptionType = OptionType>(
  options: readonly (T | GroupBase<T>)[]
): options is Options<T> => Array.isArray(options);
