import React, { useEffect } from 'react';
import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AuctionSearchQuery } from '~/App/shared/interfaces/AuctionSearchQuery';
import { SavedSearch } from '~/App/shared/interfaces/SavedSearch';
import { RecentlyViewedSearch } from '~/App/shared/interfaces/RecentlyViewedSearch';
import { CurrentSearch } from '~/App/shared/interfaces/CurrentSearch';
import { validateEmail } from '~/helpers/email';
import AuctionSaveSearchButton from './AuctionSaveSearchButton';
import { isLoggedInSelector } from '~/App/shared/selectors/sessionSelector';
import { savedSearch as savedSearchSelector } from '~/App/shared/selectors/auctionSearchSelector';
import { savedSearchesIsLoading as savedSearchesIsLoadingSelector } from '~/App/shared/selectors/savedSearchesSelector';
import {
  removeSavedSearch,
  saveSearch
} from '~/App/shared/actions/saved-searches';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  display: flex;
  justify-content: center;

  ${mq(null, 'tablet')} {
    margin: 0 auto;
    margin-top: 1rem;
  }
`;

const SaveButtonWrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    `
    min-width: 0;
    ${mq('tablet', 'laptop')} {
      min-width: 0;
    }
    ${mq('laptop')} {
      min-width: 140px;
    }
    `}
`;

interface Props {
  localSearch?: AuctionSearchQuery;
  useLocalSearch?: boolean;
  isSearchedSaved?: boolean;
  search: Partial<AuctionSearchQuery> | RecentlyViewedSearch | CurrentSearch;
  customButton?: boolean;
  email?: string;
  addToNewsletter?: boolean;
}

function isRecentSearch(
  search: Partial<AuctionSearchQuery> | RecentlyViewedSearch | CurrentSearch
): search is RecentlyViewedSearch {
  return (search as RecentlyViewedSearch).filters !== undefined;
}

const AuctionSaveSearch = ({
  isSearchedSaved = false,
  localSearch,
  search,
  customButton,
  email,
  addToNewsletter,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const savedSearch = useSelector(savedSearchSelector(search));
  const savedSearchesIsLoading = useSelector(savedSearchesIsLoadingSelector);

  const isSearchSaved = isSearchedSaved ? true : savedSearch !== undefined;
  const searchIsEmpty = Object.keys(search).length === 0;
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [isLoading, setIsLoading] = React.useState(false);
  const isInvalidForm =
    searchIsEmpty ||
    (!isLoggedIn && (!email || (email && !validateEmail(email))));

  useEffect(() => {
    setIsLoading(savedSearchesIsLoading);
  }, [setIsLoading, savedSearch, savedSearchesIsLoading]);

  const toggleSavedSearch = () => {
    setIsLoading(true);
    if (savedSearch) {
      removeSavedSearch(savedSearch, isLoggedIn);
      return;
    }

    const searchToSave = (
      isRecentSearch(search) ? search.filters : search
    ) as SavedSearch;

    dispatch(
      saveSearch(t, searchToSave, email ?? '', history.push, addToNewsletter)
    );
  };

  if (customButton) {
    return (
      <SaveButtonWrapper isLoading={savedSearchesIsLoading}>
        <AuctionSaveSearchButton
          color="secondary"
          isDisabled={isInvalidForm || isLoading}
          id="save-search-button-custom"
          isLoading={isLoading}
          isSaved={isSearchSaved}
          onClick={toggleSavedSearch}
          size="regular"
          variant={isSearchSaved ? 'outline' : 'solid'}
        />
      </SaveButtonWrapper>
    );
  }

  return (
    <Container {...props}>
      <SaveButtonWrapper isLoading={savedSearchesIsLoading}>
        <AuctionSaveSearchButton
          color="info"
          isLoading={savedSearchesIsLoading}
          onClick={toggleSavedSearch}
          variant="flat"
          size="regular"
          isSaved={isSearchSaved}
        />
      </SaveButtonWrapper>
    </Container>
  );
};

export default AuctionSaveSearch;
