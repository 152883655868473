import { SortIcon, theme } from '@kvdbil/components';
import React, { createRef, useCallback } from 'react';
import Select, { SelectInstance } from 'react-select';
import styled, { CSSObject } from 'styled-components';
import { useTranslation } from '~/Locale';
import { getOrderAlternatives } from '~/helpers/filterTranslation';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { useIsCarguideFinished } from '../../Carguide/hooks';

const SelectField = styled(Select)`
  z-index: 3;

  min-width: 12rem;
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};

  border-color: transparent;
  border: none;
  border-radius: none;

  & > div,
  & > div:hover,
  & > div:focus {
    border: none;
    border-radius: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const getOptionColor = ({
  isSelected,
  isFocused
}: {
  isSelected: boolean;
  isFocused: boolean;
}) => {
  if (isSelected) {
    return theme.colors.info.main;
  }

  if (isFocused) {
    return theme.colors.background.gray;
  }

  return theme.colors.background.light;
};

export function SortingSelect() {
  const { t } = useTranslation();
  const selectStateRef = createRef<SelectInstance>();
  const [searchParams, setSearchParam] = useSearchParams();
  const orderBy = searchParams.get('orderBy') || '-grade';
  const { isCarguideFinished } = useIsCarguideFinished();

  const handleChange = useCallback(
    value => {
      setSearchParam('orderBy', value.value);
    },
    [setSearchParam]
  );

  const orderAlternatives = getOrderAlternatives(t, isCarguideFinished);

  const found = orderAlternatives.find(option => option.value === orderBy);
  const selectLabel = found ? found.label : '';

  return (
    <Container>
      <SortIcon width={'1.5rem'} height={'1.5rem'} />
      <SelectField
        name="searchOrderSelect"
        options={orderAlternatives}
        onChange={handleChange}
        isMulti={false}
        isClearable={false}
        isSearchable={false}
        hideSelectedOptions={true}
        value={{ value: orderBy, label: selectLabel }}
        components={{
          IndicatorSeparator: () => null
        }}
        ref={selectStateRef}
        styles={{
          control: () => {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 'none',
              backgroundColor: 'transparent',
              ':focus': {
                outline: 'none'
              }
            };
          },
          input: styles => ({
            ...styles,
            ...({ height: 'auto', margin: 0, padding: 0 } as CSSObject)
          }),
          option: (styles, { isSelected, isFocused, isDisabled }) => {
            return {
              ...styles,
              ...({
                padding: '0.5rem',
                lineHeight: '1.5rem',
                fontSize: '1rem',
                fontWeight: 400,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                '&:hover': {
                  background: getOptionColor({
                    isSelected,
                    isFocused: true
                  })
                },
                background: getOptionColor({ isSelected, isFocused })
              } as CSSObject)
            };
          },
          menuList: styles => ({
            ...styles,
            ...({
              padding: '0.5rem 0',
              paddingBottom: '5rem',
              marginTop: 0,
              border: `1px solid ${theme.colors.gray.light1}`,
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem'
            } as CSSObject)
          }),
          menu: styles => ({
            ...styles,
            ...({
              borderRadius: 0,
              boxShadow: 'none',
              zIndex: 5, // This will be above some floating labels on kvd.se
              padding: '0',
              marginTop: '-0.1rem'
            } as CSSObject)
          }),
          singleValue: styles => ({
            ...styles,
            ...({
              color: theme.colors.text.dark
            } as CSSObject)
          }),
          dropdownIndicator: (styles, _) => {
            return {
              ...styles,
              ...({
                padding: 0,
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                margin: 0,
                color: theme.colors.gray.dark1,
                ':hover': {
                  color: theme.colors.text.dark
                },
                transition: `transform 100ms`,
                transform: selectStateRef.current?.props.menuIsOpen
                  ? 'rotate(-180deg)'
                  : 'rotate(0deg)',
                justifyContent: 'center',
                alignItems: 'center'
              } as CSSObject)
            };
          }
        }}
      />
    </Container>
  );
}
