import React from 'react';
import styled from 'styled-components';
import Auction from '~/App/shared/interfaces/Auction';
import { PromoSettingCMSData } from '~/App/shared/interfaces/PromoSettingCMSData';
import { Options } from './interfaces';
import { CardListItem } from './CardListItem';
import { useCardList } from './useCardList';
import { NoSearchResults } from '~/App/shared/components/NoSearchResults';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import {
  isFetchingMoreSelector,
  storeObjectsIsLoading
} from '~/App/shared/selectors/storeObjectsSelectors';
import { ObjectListDevice } from '../ObjectListContainer';

const StyledList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  column-gap: 1rem;
  row-gap: 2rem;

  width: 100%;
  box-sizing: border-box;
`;

type CardListProps = {
  auctions: Auction[];
  options: Options;
  promoSettingsData?: PromoSettingCMSData;
  debug?: boolean;
  hits: number;
  searchQuery: string;
  infinityScrollTrigger: (
    inView: boolean,
    entry: IntersectionObserverEntry
  ) => void;
  device: ObjectListDevice;
};

export const CardList = ({
  auctions,
  options,
  promoSettingsData,
  debug,
  hits,
  searchQuery,
  infinityScrollTrigger,
  device
}: CardListProps) => {
  const isFetching = useSelector(storeObjectsIsLoading);
  const isFetchingMore = useSelector(isFetchingMoreSelector(searchQuery));
  const isInitialFetching = isFetching && !isFetchingMore;
  const fetching = isFetching || isFetchingMore;

  const { cards } = useCardList({
    auctions,
    options,
    promoCards: promoSettingsData?.promoCards,
    debug,
    device,
    withNoPromoCards: isInitialFetching
  });

  const { ref } = useInView({
    triggerOnce: false,
    rootMargin: '-30% 0px 30% 0px',
    threshold: 0,
    onChange: infinityScrollTrigger
  });

  if (!fetching && hits === 0) {
    return (
      <div data-testid="search-noresults">
        <NoSearchResults searchQuery={searchQuery} />
      </div>
    );
  }

  return (
    <StyledList data-testid={`vehicle-card-list-${device}`}>
      {cards?.map(({ index, data }, i) => {
        const isLastCard = i === cards?.length - 1;
        return (
          <CardListItem
            key={`${device}-${index}-${i}`}
            currentIndex={i}
            wantedIndex={index}
            data={data}
            debug={debug}
            lastCardRef={
              isLastCard && !fetching && auctions.length < hits
                ? ref
                : undefined
            }
          />
        );
      })}
    </StyledList>
  );
};
