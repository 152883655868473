import { AuctionType } from '~/App/shared/interfaces/Auction';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { Nullable } from '~/App/shared/types/Nullable';
import { isCookieCategoryConsent } from './cookieConsent';

type Order = {
  orderId: string;
  items: { item: string; price: number; quantity: number }[];
};

type Event = [string] | [string, Order | string | undefined | null];

declare global {
  interface Window {
    ScarabQueue?: Event[];
    EMARSYS_GA_DISABLED?: boolean;
  }
}

const sendEvent = (event: Event, memberId?: string | null) => {
  if (!isCookieCategoryConsent('marketing')) {
    return;
  }

  const ScarabQueue = window?.ScarabQueue ?? [];
  if (memberId) {
    ScarabQueue.push(['setCustomerId', memberId]);
  }

  ScarabQueue.push(event);
  ScarabQueue.push(['go']);
};

export const reportProductView = (
  auctionId: string,
  memberId?: string | null
) => {
  sendEvent(['view', auctionId], memberId);
};

export const reportCategoryView = (
  category: string,
  memberId?: string | null
) => {
  sendEvent(['category', category], memberId);
};

export const reportSearchView = (
  searchTerm: string,
  memberId?: string | null
) => {
  sendEvent(['searchTerm', searchTerm], memberId);
};

export const reportPurchase = (
  bidId: string,
  auctionId: string,
  orderAmount: number,
  memberId?: string | null
) => {
  sendEvent(
    [
      'purchase',
      {
        orderId: bidId,
        items: [{ item: auctionId, price: orderAmount, quantity: 1 }]
      }
    ],
    memberId
  );
};

export const getEmarsysAuctionTypeFromAuctionSearchParams = (
  auctionType: string | null
): string => {
  if (auctionType?.toUpperCase() === AuctionType.BIDDING) {
    return 'Auction';
  }

  if (auctionType?.toUpperCase() === AuctionType.BUY_NOW) {
    return 'Fixed price';
  }

  return 'One Marketplace';
};

export const getEmarsysCategoryString = (
  brandValues: string[],
  familyNameValues: string[],
  auctionTypeValue: string | null,
  brands: { value: string; label: string }[],
  models: { value: string; label: string }[],
  mainCategory?: MainCategory
): string | null => {
  if (Object.keys(brands).length === 0) return null;

  // multiple brands selected = skip, this is no longer a category view
  if (brandValues.length > 1) return null;

  // Normalize brand name from search params
  const brand: Nullable<string> =
    brandValues.toString().split('_').join(' ') ?? null;

  // If there is more than one family name, we don't know which one is selected
  const hasMoreThanOneFamilyName = brand && familyNameValues?.length == 1;

  // models are not yet loaded, don't return category yet
  if (hasMoreThanOneFamilyName && Object.keys(models).length === 0) return null;

  const auctionType: string =
    getEmarsysAuctionTypeFromAuctionSearchParams(auctionTypeValue);

  const vehicleType: Nullable<string> =
    mainCategory?.replaceAll('_', ' ') ?? null;

  const label: Nullable<string> =
    brands?.find(b => b.value === brandValues[0])?.label || null;

  const familyNameLabel: Nullable<string> =
    (hasMoreThanOneFamilyName &&
      models?.find(
        m => m.value.toLowerCase() === familyNameValues[0].toLowerCase()
      )?.label) ||
    null;

  const unfilteredCategories: Nullable<string>[] = [
    auctionType,
    vehicleType,
    label,
    familyNameLabel
  ];

  // Removes all null values from the array
  const filteredCategories: string[] = unfilteredCategories.filter(
    Boolean
  ) as string[];

  return filteredCategories.join(' > ');
};
