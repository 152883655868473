import React from 'react';
import { Button, CarIcon, mq, TinyTitleBold } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import styled from 'styled-components';

const ShowVehiclesButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.gray};
  box-shadow: ${({ theme }) => theme.elevations.elevation2};

  padding: 0.75rem;

  ${mq('mobileM')} {
    padding: 1.25rem;
  }

  ${mq('tablet')} {
    display: none;
  }
`;

const SufixWrapper = styled(TinyTitleBold)`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

export const MobileResultsButton = ({
  hide,
  onClick,
  amount
}: {
  hide: boolean;
  onClick: () => void;
  amount: number;
}) => {
  const { t } = useTranslation();

  if (hide) {
    return null;
  }

  return (
    <ShowVehiclesButtonWrapper>
      <Button
        onClick={onClick}
        size="regular"
        fullWidth
        color="secondary"
        sufixIcon={
          <SufixWrapper>
            {amount}
            <CarIcon />
          </SufixWrapper>
        }
      >
        {t('Show the vehicles')}
      </Button>
    </ShowVehiclesButtonWrapper>
  );
};
