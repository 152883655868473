import { useCallback, useMemo } from 'react';
import { MinMaxIntervalKey, minMaxIntervalValues } from '~/config/constants';
import {
  SliderFilterSelectProps,
  SliderOptionType,
  SliderType
} from '../types';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

export const useSliderSelectData = (
  searchParamKey: MinMaxIntervalKey,
  label: string,
  step: number,
  selectedMainCategory: MainCategory,
  valueSuffix?: string,
  displayedValueFormatter?: (value: SliderType) => [string, string],
  minMaxValueOverride?: SliderType
): SliderFilterSelectProps => {
  const fromKey = `${searchParamKey}From`;
  const toKey = `${searchParamKey}To`;

  const [params, , setSearchParams] = useSearchParams();

  const minMaxValue =
    minMaxValueOverride ?? minMaxIntervalValues[searchParamKey];

  const value = useMemo<SliderType>(() => {
    const min = minMaxValue[0];
    const max = minMaxValue[1];

    const activeValueFrom = params.get(fromKey);
    const activeValueTo = params.get(toKey);

    const activeValue: SliderType = [
      (activeValueFrom && parseInt(activeValueFrom)) || min,
      (activeValueTo && parseInt(activeValueTo)) || max
    ];

    return activeValue;
  }, [fromKey, params, toKey, minMaxValue]);

  const options = useMemo<SliderOptionType[]>(() => {
    return [
      {
        label,
        value,
        searchParamKey,
        min: minMaxValue[0],
        max: minMaxValue[1],
        step,
        ...((valueSuffix && { valueSuffix }) || {}),
        ...((displayedValueFormatter && { displayedValueFormatter }) || {}),
        selectedMainCategory
      }
    ];
  }, [
    label,
    value,
    searchParamKey,
    minMaxValue,
    step,
    valueSuffix,
    displayedValueFormatter,
    selectedMainCategory
  ]);

  const activeValue = useMemo<SliderOptionType[]>(() => {
    const min = minMaxValue[0];
    const max = minMaxValue[1];

    if (!value) {
      return [];
    }

    if (value[0] === min && value[1] === max) {
      return [];
    }

    return options;
  }, [minMaxValue, value, options]);

  const onChange = useCallback(
    (valueOption: SliderOptionType[]) => {
      const min = minMaxValue[0];
      const max = minMaxValue[1];

      const currentOptionValue = valueOption && valueOption[0]?.value;

      if (!currentOptionValue) {
        setSearchParams({
          [fromKey]: undefined,
          [toKey]: undefined
        });
        return;
      }

      if (currentOptionValue[0] === min && currentOptionValue[1] === max) {
        setSearchParams({
          [fromKey]: undefined,
          [toKey]: undefined
        });
        return;
      }

      const isValueAtLimit = Number(currentOptionValue[1]) >= max;

      setSearchParams({
        [fromKey]: currentOptionValue[0].toString(),
        [toKey]: isValueAtLimit ? '' : currentOptionValue[1].toString()
      });
    },
    [fromKey, setSearchParams, toKey, minMaxValue]
  );

  return {
    name: searchParamKey,
    options,
    activeValue,
    onChange,
    type: 'SliderFilterSelect',
    selectedMainCategory
  };
};
