import React, { useCallback, useState } from 'react';
import {
  BodyText,
  Button,
  CheckmarkIcon,
  TinyTitleBold
} from '@kvdbil/components';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import SaveSearchDialog from '../../views/ObjectPage/components/SaveSearchDialog';
import { savedSearchFromSearchParams } from '~/App/shared/selectors/savedSearchesSelector';
import { useFilterSearchParams } from '../../views/FilterPage/hooks/useFilterSearchParams';

const Container = styled.div`
  padding: 2.125rem 0;
  color: ${({ theme }) => theme.colors.text.dark};
  background: ${({ theme }) => theme.colors.background.light};
`;

const TextContainer = styled.div<{ marginTop?: boolean }>`
  max-width: 40rem;
  margin: ${({ marginTop }) => (marginTop ? '1rem auto auto' : '0 auto')};
  text-align: center;
  padding: 0 1rem;
`;

const Text = styled(BodyText)`
  margin-top: 1rem;
`;

const CarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto auto;
`;

export const NoSearchResults = ({ searchQuery }: { searchQuery: string }) => {
  const { t } = useTranslation();
  const { filterSearchParams } = useFilterSearchParams([], searchQuery);

  // TODO: Add when i have a solution for search terms
  // const searchTerms = useSelector(auctionSearchTerms);
  const savedSearch = useSelector(
    savedSearchFromSearchParams(filterSearchParams.toString())
  );

  const [modalOpen, setOpen] = useState(false);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);

  return (
    <>
      <Container>
        <TextContainer>
          <CarContainer>
            <img
              src="/images/graphics/find-car.svg"
              alt={t('find car')}
              width={80}
            />
          </CarContainer>
          <TinyTitleBold as="h3">
            {t('Your search did not yield any results')}
          </TinyTitleBold>
          <Text>
            {t(
              'Sorry, your search returned no results. But persevere, we get in hundreds of new, used cars every week. Create a watch so you do not miss when the car you are looking for has been published.'
            )}
          </Text>
        </TextContainer>
        <ButtonContainer>
          <Button
            color="info"
            size="regular"
            variant={savedSearch ? 'outline' : 'solid'}
            onClick={handleOpen}
            prefixIcon={savedSearch ? <CheckmarkIcon /> : null}
          >
            {savedSearch ? t('Search watched') : t('Watch search')}
          </Button>
        </ButtonContainer>
      </Container>
      {modalOpen && (
        <SaveSearchDialog searchQuery={searchQuery} handleClose={handleClose} />
      )}
    </>
  );
};
