import { PromoCardData } from '~/App/shared/interfaces/PromoSettingCMSData';
import { CardListData, Options, PromoCardListData } from './interfaces';

export function createPromoCardLists(
  promoCards: PromoCardData[],
  options: Options
): {
  mobilePromoCards: PromoCardListData[];
  tabletPromoCards: PromoCardListData[];
  desktopPromoCards: PromoCardListData[];
} {
  const mobilePromoCards: PromoCardListData[] = [];
  const tabletPromoCards: PromoCardListData[] = [];
  const desktopPromoCards: PromoCardListData[] = [];

  // loop through all promo cards and add them to the correct list
  promoCards.forEach(
    ({
      cardPositionPhone,
      cardPositionTablet,
      cardPositionDesktop,
      promoCardTemplates,
      isActive
    }) => {
      if (!isActive || !promoCardTemplates) {
        return;
      }

      const [primaryTemplate = null, secondaryTemplate = null] =
        promoCardTemplates;

      const promoCardTemplate =
        options.showSecondaryCardTemplate && secondaryTemplate
          ? secondaryTemplate
          : primaryTemplate;

      if (!promoCardTemplate) {
        return;
      }

      mobilePromoCards.push({
        index: cardPositionPhone - 1,
        template: promoCardTemplate,
        isLoading: options.bannerIsLoading,
        fullWidth: false
      });

      tabletPromoCards.push({
        index: cardPositionTablet - 1,
        template: promoCardTemplate,
        isLoading: options.bannerIsLoading,
        fullWidth: promoCardTemplate.cardType === 'BANNER' ? true : false
      });

      desktopPromoCards.push({
        index: cardPositionDesktop - 1,
        template: promoCardTemplate,
        isLoading: options.bannerIsLoading,
        fullWidth: promoCardTemplate.cardType === 'BANNER' ? true : false
      });
    }
  );

  // sort the promo cards by index ascending to make sure they are inserted in the correct when splicing
  mobilePromoCards.sort((a, b) => a.index - b.index);
  tabletPromoCards.sort((a, b) => a.index - b.index);
  desktopPromoCards.sort((a, b) => a.index - b.index);
  return { mobilePromoCards, tabletPromoCards, desktopPromoCards };
}

export function createCardLists(
  cards: CardListData[],
  promoCards: PromoCardListData[]
): CardListData[] {
  const newCards = [...cards];

  promoCards.forEach(({ index, template, isLoading, fullWidth }) => {
    if (newCards.length < index) {
      // cant add a promo card to a position that does not exist
      return;
    }

    newCards.splice(index, 0, {
      index,
      data: {
        index,
        template,
        isLoading,
        fullWidth
      }
    });
  });

  return newCards;
}
