import React from 'react';
import { ButtonRegularText, DeleteIcon } from '@kvdbil/components';
import { StyledButton } from './StyledButton';
import { useTranslation } from '~/Locale';
import styled from 'styled-components';
import { isFilterSearchParamKey } from '~/helpers/filterSearchParams';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { FilterSearchParamKey } from '~/App/shared/types/FilterSearchParam';

export const StyledButtonText = styled(ButtonRegularText)`
  font-weight: 400;
`;

export const ClearSearchButton = () => {
  const { t } = useTranslation();
  const [searchParams, , setSearchParams] = useSearchParams();

  const handleClearSearch = () => {
    const clearedSearchParams: Record<string, string> = {};
    searchParams.forEach((value: string, key: FilterSearchParamKey) => {
      if (!isFilterSearchParamKey(key)) {
        clearedSearchParams[key] = value;
      } else {
        clearedSearchParams[key] = ''; // clear search param
      }
    });

    setSearchParams(clearedSearchParams);
  };

  return (
    <StyledButton
      variant="outline"
      color="neutral"
      size="regular"
      prefixIcon={<DeleteIcon />}
      onClick={handleClearSearch}
      data-testid="clear-search-button"
    >
      <StyledButtonText>{t('Clear')}</StyledButtonText>
    </StyledButton>
  );
};
