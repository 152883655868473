import { useCallback, useMemo, useState } from 'react';
import { OptionType } from '~/App/shared/types/OptionType';
import { reMapValue } from '../helper';
import { ToggleFilterSelectProps } from '../types';
import { trackGA4ProductFilter } from '~/helpers/client/ga4TrackEvent';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';

export const useToggleFilterSelect = ({
  activeValue,
  options,
  name,
  onChange,
  selectedMainCategory
}: ToggleFilterSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { ga4User, isHydrated } = useGA4UserIsHydrated();

  const handleChange = useCallback(
    (option: OptionType) => {
      onChange(option, name);

      if (isHydrated && option?.value && activeValue !== option?.value) {
        trackGA4ProductFilter(
          {
            filter_group: name,
            filter_value: option?.value,
            filter_page: selectedMainCategory
          },
          ga4User
        );
      }
    },
    [name, onChange, activeValue, isHydrated, ga4User, selectedMainCategory]
  );

  const value = useMemo<OptionType | null>(
    () => (activeValue && reMapValue(activeValue, options)) || null,
    [activeValue, options]
  );

  return {
    handleChange,
    value,
    isOpen,
    setIsOpen
  };
};
