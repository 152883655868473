import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmarsysCategoryString,
  reportCategoryView,
  reportSearchView
} from '~/helpers/emarsys';
import { getMyAuctions } from '~/App/shared/actions/auctions';
import {
  idToken as idTokenSelector,
  isHydratedSelector,
  memberIdSelector
} from '~/App/shared/selectors/sessionSelector';
import {
  brandFilterOptionsSelector,
  familyNameFilterOptionsSelector
} from '~/App/shared/selectors/storeObjectsSelectors';
import { useFilterSearchParams } from './useFilterSearchParams';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

export const useMyObjects = (): void => {
  const dispatch = useDispatch();

  const idToken = useSelector(idTokenSelector);

  useEffect(() => {
    idToken && dispatch(getMyAuctions());
  }, [dispatch, idToken]);
};

export const useEmarsysReport = (mainCategory?: MainCategory): void => {
  const { filterSearchParams } = useFilterSearchParams();

  const memberId = useSelector(memberIdSelector);

  const brands = useSelector(brandFilterOptionsSelector(mainCategory));
  const familyNames = useSelector(
    familyNameFilterOptionsSelector(
      brands.map(b => b.value),
      mainCategory
    )
  );

  const terms = filterSearchParams.get('terms');
  const brand = filterSearchParams.get('brand');
  const familyName = filterSearchParams.get('familyName');
  const auctionType = filterSearchParams.get('auctionType');

  const emarsysCategory = getEmarsysCategoryString(
    brand?.split(',') ?? [],
    familyName?.split(',') ?? [],
    auctionType,
    brands,
    familyNames,
    mainCategory
  );

  const isHydrated = useSelector(isHydratedSelector);

  const [lastEmarsysCategory, setLastEmarsysCategory] = useState('');

  useEffect(() => {
    if (
      isHydrated &&
      emarsysCategory &&
      lastEmarsysCategory !== emarsysCategory
    ) {
      reportCategoryView(emarsysCategory, memberId);
      setLastEmarsysCategory(emarsysCategory);
    }
  }, [isHydrated, emarsysCategory, memberId, lastEmarsysCategory]);

  useEffect(() => {
    if (isHydrated && terms) {
      reportSearchView(terms, memberId);
      setLastEmarsysCategory('');
    }
  }, [isHydrated, terms, memberId]);
};
