import React from 'react';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components';

import { OptionType } from '~/App/shared/types/OptionType';
import { BaseFilterSelect } from '../Shared/BaseFilterSelect';

import { MultiFilterSelectProps } from '../types';
import { useMultiFilterSelect } from './useMultiFilterSelect';

const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconWrapper = styled.span`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const SelectOption = ({
  data,
  children,
  ...props
}: OptionProps<OptionType, true>) => {
  if (data.icon) {
    return (
      <components.Option {...{ ...props, data }}>
        <StyledOptionWrapper>
          <StyledIconWrapper>{data.icon}</StyledIconWrapper>
          {children}
        </StyledOptionWrapper>
      </components.Option>
    );
  }

  return <components.Option {...{ ...props, data, children }} />;
};

export const MultiFilterSelect = (props: MultiFilterSelectProps) => {
  const { options, name, ...propRest } = props;

  const { handleChange, values } = useMultiFilterSelect(props);

  return (
    <BaseFilterSelect
      isMulti
      {...propRest}
      value={values}
      name={name}
      options={options}
      onChange={handleChange}
      components={{
        ...propRest.components,
        Option: SelectOption
      }}
    />
  );
};
