import React, { useState } from 'react';

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import {
  Button,
  mq,
  Dialog,
  TextField,
  Caption,
  CheckBox,
  BodyText
} from '@kvdbil/components';
import AuctionSaveSearch from '../../../shared/components/AuctionSaveSearch';
import { useSelector } from 'react-redux';
import { useTranslation } from '~/Locale';
import { useDynamicScript } from '~/App/shared/hooks/useDynamicScript';
import { googleRecaptchaSource } from '~/config/dynamicScriptsTags';

import {
  isLoggedInSelector,
  email as emailSelector
} from '~/App/shared/selectors/sessionSelector';
import { savedSearchFromSearchParams } from '~/App/shared/selectors/savedSearchesSelector';
import { useFilterSearchParams } from '~/App/views/FilterPage/hooks/useFilterSearchParams';
import { convertFilterSearchParamsToSearchQuery } from '~/helpers/searches';
import { FilterChipList } from '~/App/views/FilterPage/components/FilterChipList';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { getMinMaxIntervalValues } from '../../FilterPage/helpers';

const DialogContainer = styled.div`
  max-width: 150px;
`;

const DescriptionText = styled(BodyText)`
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
`;

const ButtonWrapper = styled.div<{ withMarginRight?: boolean }>`
  margin-right: ${({ withMarginRight }) => (withMarginRight ? '2rem' : 0)};
  ${mq(null, 'tablet')} {
    padding-bottom: 1rem;
  }

  button,
  > div {
    height: 100%;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const NewsletterContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const CaptionContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const EmailWrapper = styled.div`
  margin-top: 1rem;
`;

export interface SaveSearchDialogProps {
  handleClose: () => void;
  searchQuery?: string;
  mainCategory?: MainCategory;
}

const SaveSearchDialog = ({
  handleClose,
  searchQuery = '',
  mainCategory
}: SaveSearchDialogProps) => {
  const { t } = useTranslation();
  useDynamicScript(googleRecaptchaSource, {
    load: true
  });

  const { filterSearchParams } = useFilterSearchParams([], searchQuery);

  const isLoggedIn = useSelector(isLoggedInSelector);
  const memberEmail = useSelector(emailSelector);

  const [addToNewsletter, setAddToNewsletter] = useState(false);
  const [email, setEmail] = useState('');
  const isSearchSaved = useSelector(
    savedSearchFromSearchParams(filterSearchParams.toString())
  );

  const [searchToSave, setSearchToSave] = useState(filterSearchParams);

  const handleRemoveChip = (key: string | string[]) => {
    setSearchToSave(prev => {
      const newSearchParams = new URLSearchParams(prev);
      if (Array.isArray(key)) {
        key.forEach(k => newSearchParams.delete(k));
        return newSearchParams;
      }
      newSearchParams.delete(key);
      return newSearchParams;
    });
  };

  const search = convertFilterSearchParamsToSearchQuery(searchToSave);

  const toggleNewsletterCheck = () => {
    setAddToNewsletter(prev => !prev);
  };

  const minMaxIntervalValues = getMinMaxIntervalValues(mainCategory);

  return (
    <DialogContainer>
      <Dialog
        title={t('Create a saved search')}
        isOpen
        onClose={handleClose}
        withPortal={true}
      >
        <DescriptionText>
          {t(
            'Enter your email address and we will notify you when a suitable car is available.'
          )}
        </DescriptionText>
        <EmailWrapper>
          <TextField
            value={email || memberEmail}
            label={t('Email')}
            onChange={event => setEmail(event.target.value)}
            isDisabled={isLoggedIn}
          />
        </EmailWrapper>
        <FilterContainer>
          {t('Filters: ')}
          <FilterChipList
            searchQuery={searchToSave.toString()}
            onRemoveChip={handleRemoveChip}
            minMaxIntervalValues={minMaxIntervalValues}
          />
        </FilterContainer>
        {!isLoggedIn && (
          <NewsletterContainer>
            <CheckBox
              checked={addToNewsletter}
              onClick={toggleNewsletterCheck}
              label={t('Yes please, I want newsletters!')}
            />
          </NewsletterContainer>
        )}
        <CaptionContainer>
          <Caption>
            {t(
              'By creating this watch, you allow us to contact you via email with cars that match your search.'
            )}
          </Caption>
        </CaptionContainer>

        <ButtonContainer>
          <ButtonWrapper withMarginRight>
            <Button
              onClick={handleClose}
              size="regular"
              color="neutral"
              variant="outline"
            >
              {t('Close')}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <AuctionSaveSearch
              email={email}
              search={search}
              useLocalSearch
              isSearchedSaved={Boolean(isSearchSaved)}
              customButton
              addToNewsletter={addToNewsletter}
            />
          </ButtonWrapper>
        </ButtonContainer>
      </Dialog>
    </DialogContainer>
  );
};

export default SaveSearchDialog;
