import styled from 'styled-components';
import { Button, mq } from '@kvdbil/components';

export const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.gray.light4};
  border-width: 1px;

  :hover {
    ${({ theme }) =>
      `border-color: ${theme.colors.gray.light4};
       border-width: 1px;
      `};
  }

  padding: 0.25rem 2rem;

  ${mq(null, 'mobileL')} {
    flex-grow: 1;
  }
`;
