import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';
import { useDebounceGA4Event } from '~/App/shared/hooks/useDebounceGA4Event';
import { trackGA4SiteSearch } from '~/helpers/client/ga4TrackEvent';

export function useTrackOnSearch() {
  const location = useLocation();

  const { isHydrated, ga4User } = useGA4UserIsHydrated();
  const ga4DebounceEvent = useDebounceGA4Event();

  useEffect(() => {
    const trackOnSearch = () => {
      const locationSearch =
        window.location.search !== location.search
          ? window.location.search
          : location.search;

      const terms = new URLSearchParams(locationSearch).get('terms') ?? '';
      if (terms) {
        trackGA4SiteSearch(
          {
            search_parameter: terms,
            search_landing_page: window.location.href
          },
          ga4User
        );
      }
    };

    if (isHydrated) {
      ga4DebounceEvent(trackOnSearch);
    }
  }, [location, isHydrated, ga4DebounceEvent, ga4User]);
}
