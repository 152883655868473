import React from 'react';
import { BaseFilterSelectPorps } from '../types';
import Select from 'react-select';
import { theme } from '@kvdbil/components';
import { useBaseFilterSelect } from './useBaseFilterSelect';
import { DropdownIndicator } from './DropdownIndicator';
import { CSSObject } from 'styled-components';
import { useTranslation } from '~/Locale';

export type DefaultOptionType = {
  label: string;
  value: string;
};

export function BaseFilterSelect<
  Option = DefaultOptionType,
  IsMulti extends boolean = false
>(props: BaseFilterSelectPorps<Option, IsMulti>) {
  const { getOptionColor, ...selectProps } = useBaseFilterSelect<
    Option,
    IsMulti
  >({
    color: 'info',
    ...props
  });

  const { t } = useTranslation();

  return (
    <Select<Option, IsMulti>
      noOptionsMessage={() => t('No options')}
      {...selectProps}
      styles={{
        ...(selectProps.styles ?? {}),
        control: styles => ({
          ...styles,
          ...({
            borderRadius: 0,
            color: theme.colors.text.dark,
            fontFamily: theme.typography.fontBaseFamily,
            fontSize: '1rem',
            fontWeight: 400,
            cursor: 'pointer',
            padding: '0.7rem 0.5rem',
            backgroundColor: theme.colors.background.light,
            borderColor: theme.colors.gray.light4,
            boxShadow: 'none',
            minHeight: '3rem',
            '&:hover': {
              borderColor: 'none'
            }
          } as CSSObject)
        }),
        input: styles => ({
          ...styles,
          ...({ height: 'auto', margin: 0, padding: 0 } as CSSObject)
        }),
        placeholder: styles => ({
          ...styles,
          ...({
            fontSize: '1rem',
            lineHeight: '1.5rem',
            whiteSpace: 'nowrap', // 'nowrap' is not assignable to type 'WhiteSpace' but `as 'nowrap'` is?
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            margin: 0,
            color: theme.colors.text.dark,
            fontWeight: 600
          } as CSSObject)
        }),
        singleValue: styles => ({
          ...styles,
          ...({
            maxWidth: 'calc(100% - 3.3rem)',
            width: 'calc(100% - 3.3rem)'
          } as CSSObject)
        }),
        menuList: styles => ({
          ...styles,
          ...({
            padding: '0.5rem 0',
            paddingBottom: '5rem',
            marginTop: 0,
            border: `1px solid ${theme.colors.gray.light4}`,
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem'
          } as CSSObject)
        }),
        menu: styles => ({
          ...styles,
          ...({
            borderRadius: 0,
            boxShadow: 'none',
            zIndex: 5, // This will be above some floating labels on kvd.se
            padding: '0',
            marginTop: '-0.1rem'
          } as CSSObject)
        }),
        option: (styles, { isSelected, isFocused, isDisabled }) => ({
          ...styles,
          ...({
            padding: '0.5rem',
            lineHeight: '1.5rem',
            fontSize: '1rem',
            fontWeight: 400,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            '&:hover': {
              background: getOptionColor({
                isSelected,
                isFocused: true,
                isDisabled
              })
            },
            background: getOptionColor({ isSelected, isFocused })
          } as CSSObject)
        }),
        valueContainer: styles => ({
          ...styles,
          ...({ position: 'static', padding: 0, height: '1.5rem' } as CSSObject)
        }),
        clearIndicator: styles => ({
          ...styles,
          ...({
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: '0.2rem'
          } as CSSObject)
        })
      }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator,
        ...(props.components ?? {})
      }}
    />
  );
}
