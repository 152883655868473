import { Button, CheckIcon, SavedSearchIcon } from '@kvdbil/components';
import { ButtonProps } from '@kvdbil/components/types/components/Button';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';

const ButtonWrapper = styled(Button)`
  svg {
    font-size: 1.5rem;
  }
`;

interface Props extends Omit<ButtonProps, 'children'> {
  isSaved: boolean;
  id?: string;
}

const getPrefixIcon = (isSaved: boolean, isLoading?: boolean) => {
  if (isLoading) {
    return undefined;
  }

  return isSaved ? (
    <CheckIcon data-testid="check-icon" />
  ) : (
    <SavedSearchIcon data-testid="search-icon" />
  );
};

const AuctionSaveSearchButton = ({
  color,
  isDisabled,
  isLoading,
  isSaved,
  minWidth,
  onClick,
  size,
  variant,
  id = 'save-search-button'
}: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonWrapper
      color={color}
      data-testid={id}
      id={id}
      isDisabled={isDisabled}
      isLoading={isLoading}
      minWidth={minWidth}
      onClick={onClick}
      size={size}
      variant={variant}
      prefixIcon={getPrefixIcon(isSaved, isLoading)}
    >
      {isSaved ? t('Search watched') : t('Watch search')}
    </ButtonWrapper>
  );
};

export default AuctionSaveSearchButton;
