import React from 'react';
import { OptionType } from '~/App/shared/types/OptionType';

import { BaseFilterSelect } from '../Shared/BaseFilterSelect';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components';
import { useToggleFilterSelect } from './useToggleFilterSelect';
import { ToggleFilterSelectProps } from '../types';

export const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconWrapper = styled.span`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const SelectOption = ({
  data,
  children,
  ...props
}: OptionProps<OptionType, false>) => {
  if (data.icon) {
    return (
      <components.Option {...{ ...props, data }}>
        <StyledOptionWrapper>
          <StyledIconWrapper>{data.icon}</StyledIconWrapper>
          {children}
        </StyledOptionWrapper>
      </components.Option>
    );
  }

  return <components.Option {...{ ...props, data, children }} />;
};

export const ToggleFilterSelect = (props: ToggleFilterSelectProps) => {
  const { activeValue, options, name, ...propsRest } = props;

  const { handleChange, value } = useToggleFilterSelect(props);

  return (
    <BaseFilterSelect
      {...propsRest}
      value={value}
      name={name}
      options={options}
      onChange={handleChange}
      components={{
        ...props.components,
        Option: SelectOption
      }}
    />
  );
};
