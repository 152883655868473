import { useCallback } from 'react';
import { Options } from 'react-select';
import { MultiFilterSelectProps, OptionType } from '../types';

import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';

export const useGeneralMultiFilterSelect = (
  props: MultiFilterSelectProps
): MultiFilterSelectProps => {
  const { options, name } = props;

  const activeValue = useParamFromQuery(name);
  const [, setSearchParam] = useSearchParams();

  const handleChange = useCallback(
    (options: Options<OptionType>, name) => {
      setSearchParam(name, options?.map(o => o.value) ?? []);
    },
    [setSearchParam]
  );

  return {
    ...props,
    options: options,
    onChange: handleChange,
    activeValue: activeValue?.split(',').sort() ?? []
  };
};
