import React from 'react';
import { Caption, CheckmarkIcon, Stamp, Title, mq } from '@kvdbil/components';
import {
  StructuredText as StructuredTextBase,
  StructuredTextGraphQlResponse
} from 'react-datocms/structured-text';
import { renderRule, isParagraph } from 'datocms-structured-text-utils';

import {
  IconListRecord,
  StampRecord,
  TitleRecord
} from '~/config/generated/graphql';
import { locale } from '~/config/public/environment';
import { Color, StampType, Variant } from '@kvdbil/components/types/Types';
import { StructuredTextData } from '../../interfaces/datoCMS';
import { Nullable } from '../../types/Nullable';
import styled from 'styled-components';
import { getSvOrEnLocale } from '~/App/shared/localization/helpers';

export const Heading = styled(Title)`
  color: inherit; // to get cards color from cms settings
  width: 100%;
  text-align: center;

  ${mq('tablet')} {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`;

export const List = styled.ul`
  text-align: left;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0;
`;

export const IconWrapper = styled.div`
  font-size: 1.5rem;
  height: 1.5rem;
  display: flex;
  margin-right: 0.5rem;
`;

export const CardContent = ({
  data,
  textAs = 'p'
}: {
  data: Nullable<StructuredTextData>;
  textAs?: React.ElementType;
}) => {
  return (
    <StructuredTextBase
      data={data as StructuredTextGraphQlResponse}
      customRules={[
        renderRule(
          isParagraph,
          ({ adapter: { renderNode }, children, key }) => {
            return renderNode(textAs, { key, as: 'p' }, children);
          }
        )
      ]}
      renderBlock={({ record }) => {
        switch (record.__typename) {
          case 'StampRecord':
            const stamp = record as StampRecord;
            return (
              <Stamp
                type={stamp.stampType as StampType}
                color={stamp.stampColor as Color}
                appearance={stamp.stampAppearance as Exclude<Variant, 'flat'>}
                locale={getSvOrEnLocale(locale)}
                size={record.stampSize as number}
              />
            );
          case 'TitleRecord':
            const title = record as TitleRecord;
            return (
              <Heading as={(title?.tag as never) ?? 'h3'}>{title.text}</Heading>
            );

          case 'IconListRecord':
            const iconList = record as IconListRecord;
            return (
              <List>
                {iconList.items.map(item => (
                  <ListItem key={item.text}>
                    <IconWrapper>
                      {(() => {
                        switch (item.imageIcon?.icon?.title) {
                          case 'checkmark-icon':
                            return <CheckmarkIcon />;
                          default:
                            return <CheckmarkIcon />;
                        }
                      })()}
                    </IconWrapper>
                    <Caption as="span">{item.text}</Caption>
                  </ListItem>
                ))}
              </List>
            );
        }

        return null;
      }}
    />
  );
};
