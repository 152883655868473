import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDynamicFilterValues,
  fetchFacilities,
  fetchStoreObjects
} from '~/App/shared/actions/storeObjectActions';
import { PromoSettingCMSData } from '~/App/shared/interfaces/PromoSettingCMSData';
import {
  filteredEntitiesSelector,
  hitsSelector,
  isFetchingMoreSelector,
  remainingSelector,
  storeObjectsIsLoading
} from '~/App/shared/selectors/storeObjectsSelectors';
import { useTranslation } from '~/Locale';
import { deserializationFilters } from '~/helpers/storeObjects';
import { CardList } from './CardList';
import styled from 'styled-components';
import { Spacer, mq } from '@kvdbil/components';
import { isMainCategory } from '~/helpers/filterSearchParams';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { FILTER_PAGE_STEP_SIZE } from '~/config/constants';
import { useIsCarguideFinished } from '../../Carguide/hooks';
import { useIsDevice } from '~/App/shared/hooks/useIsDevice';
import { getInitialAmountOfSkeletons } from '../helpers';
import { BodyTextSkeleton } from '../../Blog/components/BlogSkeletons';

const HitsWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${mq('tablet')} {
    flex-direction: row;
    align-items: flex-start;
  }

  > * {
    flex: 1;
  }
`;

const HitsInfoText = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.gray.dark3};
  display: flex;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
`;

export type ObjectListDevice = 'mobile' | 'tablet' | 'desktop';

interface ObjectListContainerProps {
  promoSettingsData?: PromoSettingCMSData;
  searchQuery?: string;
  skipInitialFetch?: boolean;
  mainCategory?: MainCategory;
}

export const ObjectListContainer = ({
  promoSettingsData,
  searchQuery = '',
  skipInitialFetch = false,
  mainCategory
}: ObjectListContainerProps) => {
  const { t } = useTranslation();
  const isFetching = useSelector(storeObjectsIsLoading);
  const entities = useSelector(filteredEntitiesSelector(searchQuery));
  const hits = useSelector(hitsSelector(searchQuery));
  const { isCarguideFinished } = useIsCarguideFinished();

  const hasCertifiedObjects = useMemo(
    () => entities.some(({ processObject }) => processObject?.certified),
    [entities]
  );

  const filteredPromoData = useMemo(() => {
    if (isMainCategory(mainCategory)) {
      return {
        usageType: promoSettingsData?.usageType ?? null,
        promoCards:
          promoSettingsData?.promoCards?.filter(promoCard =>
            promoCard.applyForCategory.includes(mainCategory)
          ) ?? []
      };
    }
  }, [promoSettingsData, mainCategory]);

  const { isSmallerThan, isGreaterThan } = useIsDevice();
  const device = useMemo((): ObjectListDevice => {
    if (isSmallerThan('tablet')) {
      return 'mobile';
    }
    if (isGreaterThan('tablet') && isSmallerThan('laptop')) {
      return 'tablet';
    }
    return 'desktop';
  }, [isSmallerThan, isGreaterThan]);

  const { infinityScrollTrigger, objectList } = useFilteredObjects(
    searchQuery,
    skipInitialFetch,
    device
  );

  return (
    <>
      <CardList
        auctions={objectList}
        options={{
          isCertifiedAuctionsAvailable: hasCertifiedObjects,
          showSecondaryCardTemplate: Boolean(isCarguideFinished)
        }}
        promoSettingsData={filteredPromoData}
        hits={hits}
        searchQuery={searchQuery}
        infinityScrollTrigger={infinityScrollTrigger}
        device={device}
      />

      <HitsWrapper>
        <Spacer />
        <HitsInfoText>
          {isFetching ? (
            <BodyTextSkeleton style={{ width: '150px' }} />
          ) : (
            t('Showing %1$d of %2$d hits', objectList.length, hits ?? 0)
          )}
        </HitsInfoText>
        <Spacer />
      </HitsWrapper>
    </>
  );
};

export default ObjectListContainer;

const useFilteredObjects = (
  searchQuery = '',
  skipInitialFetch = false,
  device: ObjectListDevice
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFetching = useSelector(storeObjectsIsLoading);
  const entities = useSelector(filteredEntitiesSelector(searchQuery));
  const remaining = useSelector(remainingSelector(searchQuery));
  const isFetchingMore = useSelector(isFetchingMoreSelector(searchQuery));
  const shouldFetch = useRef<boolean>(!skipInitialFetch);
  const hasFetchedInitial = useRef<boolean>(skipInitialFetch);

  // when this updates new data will be fetched
  const filters = useMemo(() => {
    return deserializationFilters(searchQuery ?? '');
  }, [searchQuery]);

  const objectList = useMemo(() => {
    const amountOfSkeletons = isFetchingMore
      ? Math.min(remaining, FILTER_PAGE_STEP_SIZE)
      : getInitialAmountOfSkeletons(device);
    const loaders = Array(amountOfSkeletons).fill({
      isLoader: true
    });

    return isFetching || isFetchingMore ? [...entities, ...loaders] : entities;
  }, [entities, isFetching, isFetchingMore, remaining, device]);

  // TODO: Get a real solution, this is just to keep search updated for now
  const infinityScrollTrigger = useCallback(
    async (inView: boolean) => {
      const nextOffset = objectList.length;

      const shouldFetchMore = inView && remaining > 0 && !isFetchingMore;

      if (shouldFetchMore) {
        await dispatch(
          fetchStoreObjects({
            filters,
            offset: nextOffset
          })
        );
      }
    },
    [objectList.length, remaining, isFetchingMore, dispatch, filters]
  );

  useEffect(() => {
    // TODO: [KVDBIL-1635] Cant use this server side doe to translationFunction dependency
    if (/*shouldFetch.current &&*/ isMainCategory(filters.vehicleType)) {
      dispatch(fetchDynamicFilterValues(filters.vehicleType, t));
    }
  }, [dispatch, filters.vehicleType, t]);

  // only need fetch facilities once
  useEffect(() => {
    if (hasFetchedInitial.current) {
      dispatch(fetchFacilities());
    }
  }, [dispatch]);

  useEffect(() => {
    if (shouldFetch.current) {
      dispatch(
        fetchStoreObjects({
          filters
        })
      );
    }

    shouldFetch.current = true;
  }, [dispatch, filters]);

  return {
    infinityScrollTrigger,
    objectList
  };
};
