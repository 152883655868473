import { useTranslation } from '~/Locale';
import { SliderFilterSelectProps } from '../types';

import { useSliderSelectData } from '../SliderFilter/useSliderSelectData';
import { MinMaxIntervalKey } from '~/config/constants';

export const useYearModelFilterSelect = (
  props: SliderFilterSelectProps
): SliderFilterSelectProps => {
  const { name } = props;

  const { t } = useTranslation();

  return useSliderSelectData(
    name as MinMaxIntervalKey,
    t('Year'),
    1,
    props.selectedMainCategory
  );
};
