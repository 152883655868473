import React from 'react';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { StructuredTextData } from '~/App/shared/interfaces/datoCMS';

interface Props {
  text: StructuredTextData;
}

const InformationSection = ({ text }: Props) => {
  if (!text) return null;

  return <StructuredText data={text} />;
};

export default InformationSection;
