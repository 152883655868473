import React, { useCallback } from 'react';
import { MobileResultsButton } from './MobileResultsButton';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  Button,
  CloseIcon,
  FilterIcon,
  Headline,
  mq
} from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import { hitsSelector } from '~/App/shared/selectors/storeObjectsSelectors';

const Modal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${mq('tablet')} {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  background-color: ${({ theme }) => theme.colors.background.light};
`;

const StyledFlexButton = styled(Button)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${mq('tablet')} {
    display: none;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ModalBody = styled.div`
  margin-bottom: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  searchQuery: string;
};

export const FilterModal = ({
  children,
  isOpen,
  onToggle,
  searchQuery
}: Props) => {
  const { t } = useTranslation();

  const hits = useSelector(hitsSelector(searchQuery));

  const handleToggleModel = useCallback(() => {
    onToggle();
  }, [onToggle]);

  if (!isOpen) {
    return (
      <StyledFlexButton
        onClick={handleToggleModel}
        variant="flat"
        color="info"
        prefixIcon={
          <FilterIcon
            width={'1.5rem'}
            height={'1.5rem'}
            title={t('FILTER_ICON_TOOLTIP')}
          />
        }
      >
        {t('Show filters')}
      </StyledFlexButton>
    );
  }

  return (
    <Modal>
      <ModalHeader>
        <Headline>{t('Filter')}</Headline>
        <CloseIcon width={'2rem'} height={'2rem'} onClick={handleToggleModel} />
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      <MobileResultsButton
        hide={false}
        onClick={handleToggleModel}
        amount={hits}
      />
    </Modal>
  );
};
