/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import { createSelector } from 'reselect';
import {
  has,
  map,
  sort,
  either,
  pickBy,
  propOr,
  compose,
  when,
  is,
  equals,
  prop,
  dissoc,
  omit,
  toString,
  identity,
  find,
  Ord
} from 'ramda';
import { ReduxStore } from '../interfaces/store';
import { SavedSearches } from '../interfaces/store/SavedSearches';
import {
  AuctionSearchParams,
  EvolvedSearchParams
} from '../interfaces/AuctionSearchParams';
import { SavedSearch } from '../interfaces/SavedSearch';
import { AuctionSearchQuery } from '../interfaces/AuctionSearchQuery';
import { RecentlyViewedSearch } from '../interfaces/RecentlyViewedSearch';
import {
  createIntervalKeys,
  createIntervals,
  isNotNil,
  SEARCH_QUERY_INTERVAL_KEYS
} from '~/helpers/auctionSearch';
import { CurrentSearch } from '../interfaces/CurrentSearch';
import { isMultiselectSearchParamKey } from '~/helpers/filterSearchParams';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export type AuctionSearchKey = keyof EvolvedSearchParams;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export type AuctionSearchQueryKey = keyof Partial<AuctionSearchQuery>;

const removeFalsyEntries = ([_, value]: [_: unknown, value: unknown]) => {
  return Boolean(value);
};

export const buildStateUrl = (
  state: EvolvedSearchParams | Partial<AuctionSearchQuery>
) => {
  const parts = Object.entries(state)
    .filter(removeFalsyEntries)
    .map(([key, value]: [key: string, value: string]) => `${key}=${value}`);

  const queryString = parts.join('&');

  return queryString ? `?${queryString}` : '';
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const decodeInterval =
  (type: keyof AuctionSearchQuery | keyof EvolvedSearchParams) =>
  (obj: Partial<AuctionSearchQuery>): Partial<AuctionSearchParams> => {
    const typeTo = `${type}To` as keyof AuctionSearchQuery;
    const typeFrom = `${type}From` as keyof AuctionSearchQuery;

    const hasSearchKey = either(has(typeTo), has(typeFrom))(obj);

    if (!hasSearchKey) {
      return obj;
    }

    const typeToValue = prop(typeTo, obj) || null;
    const typeFromValue = prop(typeFrom, obj) || null;

    /* Delete keys when we've already retrieved the values */
    const objWithoutKeys = omit([typeTo, typeFrom], obj);

    return {
      ...objWithoutKeys,
      [type]: [typeFromValue, typeToValue]
    };
  };

export const convertMultiselectSearchParamFromStringToArray = (
  obj: Partial<EvolvedSearchParams>
) => {
  return Object.entries(obj).reduce((acc, [paramName, paramValue]) => {
    acc[paramName] =
      isMultiselectSearchParamKey(paramName) && typeof paramValue === 'string'
        ? paramValue.split(',')
        : paramValue;
    return acc;
  }, {} as Record<string, AuctionSearchQuery[keyof AuctionSearchQuery]>);
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const savedSearchesRoot = createSelector<
  ReduxStore,
  SavedSearches,
  SavedSearches
>(prop('savedSearches'), identity);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const destructIntervals =
  (params: AuctionSearchQueryKey[]) =>
  (searchParams: Partial<AuctionSearchQuery>): Partial<AuctionSearchQuery> =>
    params.reduce((acc, param) => {
      return decodeInterval(param)(acc);
    }, searchParams);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const savedSearchesList = createSelector<
  ReduxStore,
  SavedSearches,
  SavedSearch[]
>(
  savedSearchesRoot,
  compose<SavedSearches, SavedSearch[], SavedSearch[]>(
    map(search => ({
      ...search,
      filters: dissoc('includeComingSoonAuctions', search.filters)
    })),
    propOr([], 'list')
  )
);

const diff = (a: Ord, b: Ord) => (a > b ? 1 : -1);
const sortArraysInObject = (obj: unknown) =>
  map(when(is(Array), compose(map(when(is(Number), toString)), sort(diff))))(
    obj as unknown[]
  );

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const filterMatch = (params: Record<string, unknown>) =>
  compose(equals(sortArraysInObject(params)), sortArraysInObject);

export const isRecentlyViewedSearch = (
  search: Partial<AuctionSearchQuery> | RecentlyViewedSearch | CurrentSearch
): search is RecentlyViewedSearch => {
  return search && (search as RecentlyViewedSearch).filters !== undefined;
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 * @see savedSearchesSelector.ts
 */
export const savedSearch = (
  params:
    | Partial<AuctionSearchQuery>
    | Partial<RecentlyViewedSearch>
    | CurrentSearch
) =>
  createSelector(savedSearchesList, (list: SavedSearch[]) => {
    const compareParams = compose<
      Partial<AuctionSearchQuery>,
      Partial<AuctionSearchQuery>,
      Partial<AuctionSearchQuery>,
      Partial<AuctionSearchQuery>,
      Partial<AuctionSearchQuery>,
      Partial<AuctionSearchQuery>
    >(
      convertMultiselectSearchParamFromStringToArray,
      dissoc('auctionType'),
      dissoc('orderBy'),
      dissoc('includeComingSoonAuctions'),
      createIntervals(createIntervalKeys)
    )(isRecentlyViewedSearch(params) ? params.filters : params);

    return find<SavedSearch>(
      compose(
        filterMatch(compareParams),
        compose<Partial<AuctionSearchQuery>, Partial<AuctionSearchQuery>>(
          dissoc('includeComingSoonAuctions'),
          prop('filters') as () => Partial<AuctionSearchQuery>
        )
      )
    )(list);
  });

const destructIntervalsKeys =
  SEARCH_QUERY_INTERVAL_KEYS as AuctionSearchQueryKey[];

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const decodeFilters = compose(
  destructIntervals(destructIntervalsKeys),
  pickBy(isNotNil)
);
