import RCSlider from 'rc-slider';
import styled from 'styled-components';

export const SliderWrapper = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

export const Slider = styled(RCSlider).attrs({ className: 'slider' })`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;

  && .rc-slider-track {
    background: ${({ theme }) => theme.colors.info.main};
    height: 0.188rem;
  }

  && .rc-slider-rail {
    border: none;
    background: ${({ theme }) => theme.colors.gray.light4};
    border-radius: 0;
    height: 0.2rem;
  }

  && .rc-slider-handle {
    background: ${({ theme }) => theme.colors.info.main};
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);

    height: 1.5rem;
    width: 1.5rem;
    top: 0.3rem;
  }
`;
