import React, { RefObject } from 'react';
import { BaseFilterSelect } from '../Shared/BaseFilterSelect';
import { components, ControlProps, MenuListProps } from 'react-select';
import useEventListener from '~/App/shared/hooks/useEventListener';
import {
  BaseFilterSelectPorps,
  SliderFilterSelectProps,
  SliderOptionType
} from '../types';
import { useSliderFilterSelect } from './useSliderFilterSelect';
import { SliderOption } from './SliderOption';

const Control = (props: ControlProps<SliderOptionType, true>) => {
  const { toggleMenu, openMenu, closeMenu } =
    props.selectProps as BaseFilterSelectPorps<SliderOptionType, true>;

  return (
    <div
      role="menu"
      tabIndex={0}
      onMouseDown={toggleMenu}
      onTouchEnd={toggleMenu}
      onKeyDown={e => {
        if (e.key === 'ArrowDown') {
          openMenu && openMenu();
        }

        if (e.key === 'Escape') {
          closeMenu && closeMenu();
        }
      }}
    >
      <components.Control {...props} />
    </div>
  );
};

const MenuList = (props: MenuListProps<SliderOptionType, true>) => (
  <components.MenuList {...props}>
    {props.options.map((option: SliderOptionType) => (
      <SliderOption
        {...option}
        key={option.label}
        hideLabel={props.options.length === 1}
        onChange={props.selectProps.onChange}
        getSelectValue={props.getValue}
        selectedMainCategory={option.selectedMainCategory}
      />
    ))}
  </components.MenuList>
);

export const SliderFilterSelect = (props: SliderFilterSelectProps) => {
  const { options, onChange, name, ...propRest } = props;

  const { ref, isOpen, setIsOpen, handleChange, value } =
    useSliderFilterSelect(props);

  return (
    <div ref={ref}>
      <BaseFilterSelect<SliderOptionType, true>
        {...propRest}
        value={value || []}
        name={name}
        options={options}
        components={{
          MenuList,
          Control
        }}
        onChange={handleChange}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        toggleMenu={() => setIsOpen(pre => !pre)}
        openMenu={() => setIsOpen(true)}
        closeMenu={() => setIsOpen(false)}
      />
    </div>
  );
};

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
  useEventListener(mouseEvent, event => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node)) {
      return;
    }

    handler(event);
  });
}
