import { useCallback, useRef, useState } from 'react';
import { SliderFilterSelectProps, SliderOptionType } from '../types';
import { useOnClickOutside } from './SliderFilterSelect';

export const useSliderFilterSelect = ({
  onChange,
  activeValue,
  name,
  placeholder,
  label
}: SliderFilterSelectProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (options: SliderOptionType[]) => {
      onChange(options, name);
    },
    [name, onChange]
  );

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return {
    ref,
    isOpen,
    setIsOpen,
    handleChange,
    value: activeValue,
    label: label ?? placeholder ?? ''
  };
};
