import { useCallback, useMemo, useState } from 'react';
import { Options } from 'react-select';
import { OptionType } from '~/App/shared/types/OptionType';
import { reMapValues } from '../helper';
import { MultiFilterSelectProps } from '../types';
import { trackGA4ProductFilter } from '~/helpers/client/ga4TrackEvent';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

export const useMultiFilterSelect = ({
  activeValue,
  onChange,
  options,
  name,
  placeholder,
  label,
  selectedMainCategory
}: MultiFilterSelectProps & { selectedMainCategory: MainCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { ga4User, isHydrated } = useGA4UserIsHydrated();

  const handleChange = useCallback(
    (options: Options<OptionType>) => {
      onChange(options, name);

      const selectedOption = options?.find(
        ({ value }) => !activeValue?.includes(value)
      );

      if (isHydrated && selectedOption) {
        trackGA4ProductFilter(
          {
            filter_group: name,
            filter_value: selectedOption?.value,
            filter_page: selectedMainCategory
          },
          ga4User
        );
      }
    },
    [name, onChange, activeValue, isHydrated, ga4User, selectedMainCategory]
  );

  const values = useMemo(
    () => (options && activeValue ? reMapValues(activeValue, options) : []),
    [activeValue, options]
  );

  return {
    isOpen,
    setIsOpen,
    handleChange,
    values,
    label: label ?? placeholder ?? ''
  };
};
