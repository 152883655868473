import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';
import { Flex, mq, mqSizes } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import { createStructuredBreadcrumbs } from '~/helpers/seo';
import Head from '~/App/shared/components/Head';
import Section from '~/App/shared/components/Layout/Section';
import LazyComponentFallback from '~/App/shared/components/LazyComponentFallback';
import { useTrackOnSearch } from './hooks/useTrackOnSearch';
import { useEmarsysReport, useMyObjects } from './hooks/useMyObjects';
import { FilterControls } from './components/FilterControls';
import { SelectedFilters } from './components/SelectedFilters';
import { FilterSelector } from './components/FilterSelector/FilterSelector';
import AllBrandsSection from '~/App/views/CarTreeAndCategory/components/AllBrandsSection';
import {
  PromoSettingData,
  FilterPageData
} from '~/App/shared/interfaces/store/CmsData';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import {
  PromoSettingByUsageTypeDocument,
  PromoSettingByUsageTypeQuery,
  FilterPageDocument,
  FilterPageQuery
} from '~/config/generated/graphql';
import TopContent from './components/TopContent';
import InformationSection from './components/InformationSection';
import { HitAndSorting } from '~/App/views/FilterPage/components/HitAndSorting';
import { hitsSelector } from '~/App/shared/selectors/storeObjectsSelectors';
import { Redirect, useLocation } from 'react-router-dom';

import { useFilterSearchParams } from './hooks/useFilterSearchParams';
import ObjectListContainer from './components/ObjectListContainer';
import { useTrackCategoryView } from './hooks/useTrackCategoryView';
import { shouldRedirect } from '~/helpers/filterPageRedirection';
import TrustPilotTop from '~/App/shared/components/TrustPilotTop';
import MainCategoriesSection, {
  MainCategoryBlurb
} from '~/App/shared/components/MainCategoriesSection';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { isMainCategory } from '~/helpers/filterSearchParams';
import { useFilterPageActive } from './hooks/filterPageScrollHooks';
import { RouteComponentProps } from '~/App/shared/interfaces/routes';
import KvdHelperWrapper from '~/App/shared/components/KvdHelperWrapper';

const LazyRecentlyViewedAuctions = loadable(
  () => import('../../shared/components/RecentlyViewedAuctions'),
  {
    fallback: <LazyComponentFallback />
  }
);

const Container = styled(Flex)`
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.background.light};
`;

const MainCategoryContainer = styled(Flex)`
  ${mq('tablet')} {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

export const TopContainer = styled(Flex)`
  max-width: ${mqSizes.laptop};
  padding-top: 2rem;
  padding-bottom: 1rem;

  ${mq(null, 'laptop')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ContentOuterContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background.gray};
`;

const ContentInnerContainer = styled.div`
  width: 100%;

  max-width: 1024px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${mq(null, 'laptop')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export type FilterPageProps = RouteComponentProps & {
  mainCategory?: MainCategory;
  skipInitialFetch: boolean;
};

export const FilterPage: React.FC<FilterPageProps> = ({
  mainCategory,
  skipInitialFetch
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  // TODO: this will lock to main category for now, but i might need to add this to a universal hook
  const searchQuery = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    // lock to main category
    if (isMainCategory(mainCategory)) {
      searchParams.append('vehicleType', mainCategory);
    }

    if (!searchParams.has('orderBy')) {
      searchParams.append('orderBy', '-grade');
    }

    return searchParams.toString();
  }, [mainCategory, search]);

  const { filterSearchParamsSize } = useFilterSearchParams(['vehicleType']);

  const hits = useSelector(hitsSelector(searchQuery));

  const { data: cmsData } = useCmsData<FilterPageQuery, FilterPageData>({
    storeKey: 'filterPage',
    storeNestedKey: mainCategory ?? 'all',
    query: FilterPageDocument,
    options: {
      variables: {
        mainCategory: mainCategory ?? 'all'
      }
    }
  });

  const { data: promoData } = useCmsData<
    PromoSettingByUsageTypeQuery,
    PromoSettingData
  >({
    storeKey: 'promoSettings',
    storeNestedKey: 'promoSettings.filter_page',
    query: PromoSettingByUsageTypeDocument,
    options: {
      variables: {
        usageType: 'filter_page'
      }
    }
  });

  useMyObjects();

  useTrackCategoryView();

  useTrackOnSearch();

  useEmarsysReport(mainCategory);

  useFilterPageActive();

  const schema = createStructuredBreadcrumbs([
    {
      item: `https://${t('PAGE_TITLES.TEMPLATE')}`,
      name: t('used-cars')
    },
    {
      item: `https://${t('PAGE_TITLES.TEMPLATE')}/${t('ROUTES.FILTER_CAR')}`,
      name: t('ROUTES.FILTER_CAR')
    }
  ]);

  const { ref: recentlyViewedRef, inView: recentlyViewedInView } = useInView({
    triggerOnce: true
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Head
        title={cmsData?.seo?.title || t('PAGE_TITLES.CAR_AUCTIONS')}
        meta={[
          {
            name: 'description',
            content:
              cmsData?.seo?.description || t('PAGE_DESCRIPTIONS.CAR_AUCTIONS')
          }
        ]}
      />
      <KvdHelperWrapper url={cmsData?.kvdHelperUrl ?? ''}>
        <TrustPilotTop isFilterPage={true} />

        <Container
          data-testid="list-wrapper"
          justify="center"
          align="center"
          direction={'column'}
        >
          <TopContainer
            basis={1}
            grow={1}
            direction="column"
            gap={{
              mobileS: '1.5rem',
              tablet: '1rem'
            }}
          >
            {cmsData && (
              <TopContent
                title={cmsData?.title}
                description={cmsData?.description}
              />
            )}

            {(mainCategory && (
              <FilterSelector
                mainCategory={mainCategory}
                searchQuery={searchQuery}
              />
            )) || (
              <MainCategoryContainer>
                <MainCategoriesSection
                  mainCategories={cmsData?.categoryBlurbs as MainCategoryBlurb}
                />
              </MainCategoryContainer>
            )}

            {filterSearchParamsSize > 0 && (
              <Flex
                direction={{
                  mobileS: 'column',
                  tablet: 'row'
                }}
                justify="space-between"
                align={'center'}
              >
                <SelectedFilters
                  searchQuery={searchQuery}
                  mainCategory={mainCategory}
                />
                <FilterControls
                  searchQuery={searchQuery}
                  mainCategory={mainCategory}
                />
              </Flex>
            )}
          </TopContainer>

          <ContentOuterContainer>
            <ContentInnerContainer>
              <ContentWrapper>
                <HitAndSorting hits={hits} />
              </ContentWrapper>
              <ContentWrapper>
                <ObjectListContainer
                  skipInitialFetch={skipInitialFetch}
                  promoSettingsData={promoData}
                  searchQuery={searchQuery}
                  mainCategory={mainCategory}
                />
              </ContentWrapper>
            </ContentInnerContainer>
          </ContentOuterContainer>

          {mainCategory && <AllBrandsSection mainCategory={mainCategory} />}

          {cmsData?.seoInformation && (
            <Section backgroundColor="light">
              <InformationSection text={cmsData?.seoInformation} />
            </Section>
          )}

          <Section backgroundColor="light" spacing={3.5} divider="bottom">
            <div className="" ref={recentlyViewedRef}>
              {recentlyViewedInView && <LazyRecentlyViewedAuctions />}
            </div>
          </Section>
        </Container>
      </KvdHelperWrapper>
    </>
  );
};

const RedirectHandler = ({
  skipInitialFetch,
  mainCategory,
  staticContext,
  location,
  ...rest
}: FilterPageProps) => {
  const { t } = useTranslation();

  const redirectURL = shouldRedirect(
    t,
    `${location.pathname}${location.search}`
  );

  if (redirectURL.redirect) {
    staticContext && (staticContext.statusCode = 301);

    return (
      <Redirect
        to={{
          pathname: redirectURL.pathname,
          search: redirectURL.search,
          status: 301
        }}
      />
    );
  }

  return (
    <FilterPage
      mainCategory={mainCategory}
      skipInitialFetch={skipInitialFetch}
      location={location}
      {...rest}
    />
  );
};

export default RedirectHandler;
