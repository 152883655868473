import { CaptionLink, Flex, mq } from '@kvdbil/components';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import TrustPilot from '~/App/shared/components/TrustPilot';
import { useTranslation } from '~/Locale';
import { PromoCardBaseTemplate } from '../../interfaces/PromoSettingCMSData';
import { Nullable } from '../../types/Nullable';
import { ColorField } from '~/config/generated/graphql';
import { Heading } from './CardContent';
import { trackGA4FilterContentCard } from '~/helpers/client/ga4TrackEvent';
import { useGA4User } from '../../hooks/useGA4User';

interface ContainerProps {
  $backgroundColor: Nullable<ColorField>;
  $color: Nullable<ColorField>;
}

const Container = styled.a<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 20rem;
  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor?.hex ?? theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.dark};
  padding: 2rem 1rem;
  box-shadow: ${({ theme }) => theme.elevations.elevation2};
  border-radius: 0.5rem;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color.hex};
    `};

  ${mq('tablet')} {
    padding: 1rem;
  }
`;

const LinkText = styled(CaptionLink)<ContainerProps>`
  color: ${({ theme, $color }) => $color?.hex ?? theme.colors.text.dark};
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type PromoCardTrustPilotProps = PromoCardBaseTemplate;

const PromoCardTrustPilot = ({
  title,
  backgroundColor,
  textColor,
  link,
  ga4EventIdentifier
}: PromoCardTrustPilotProps) => {
  const { t } = useTranslation();

  const { url, text } = link || {
    text: t('TRUSTPILOT_PRODUCT_CARD.LINK_TEXT')
  };

  const ga4User = useGA4User();

  const handleOnClick = useCallback(() => {
    trackGA4FilterContentCard({ card_type: ga4EventIdentifier }, ga4User);
  }, [ga4EventIdentifier, ga4User]);

  return (
    <Container
      href={url ?? 'https://se.trustpilot.com/review/kvd.se'}
      target="_blank"
      rel="noreferrer"
      $backgroundColor={backgroundColor}
      $color={textColor}
      onClick={handleOnClick}
      data-testid="promo-card-trustpilot"
    >
      <Flex direction={'column'} align={'center'} gap={2}>
        <Heading as="h3">{title}</Heading>
        <TrustPilot type="Mini" disableLinks={true} checkDelay={1000} />
      </Flex>
      <LinkText as="span" $backgroundColor={backgroundColor} $color={textColor}>
        {text}
      </LinkText>
    </Container>
  );
};

export default PromoCardTrustPilot;
