import React from 'react';
import ProductCard from '~/App/shared/components/ProductCard/ProductCard';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import Auction from '~/App/shared/interfaces/Auction';
import { CardIndexDebugger } from './CardIndexDebugger';
import { PromoCardSwitch } from '../../../../shared/components/Promo/PromoCardSwitch';
import styled, { css } from 'styled-components';
import { mq } from '@kvdbil/components';
import { environment } from '~/config/public';
import { PromoCardListData } from './interfaces';

type StyledCListItemProps = {
  fullWidth?: boolean;
  debug?: boolean;
};

export const StyledListItem = styled.li<StyledCListItemProps>`
  flex-basis: 100%;
  max-width: 100%;

  /* if is not a full width component is a normal card */
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      /* 2 cards */
      // mobileL is way to small for 2 card columns looks like ~560 would be the sweet spot...
      @media (min-width: 560px) {
        /* removes 1 gap */
        max-width: calc(50% - 0.5rem);
      }

      /* 3 cards */
      ${mq('tablet')} {
        /* removes 2 gaps */
        max-width: calc(33% - 0.66rem);
      }

      /* 4 cards */
      ${mq('laptop')} {
        /* removes 3 gaps */
        max-width: calc(25% - 0.75rem);
      }
    `}

  position: ${({ debug }) => (debug ? 'relative' : 'static')};
`;

export const CardListItem = ({
  currentIndex,
  wantedIndex,
  data,
  debug = false,
  lastCardRef
}: {
  currentIndex: number;
  wantedIndex: number;
  data: PromoCardListData | Auction;
  debug?: boolean;
  lastCardRef?: (node?: Element | null) => void;
}) => {
  if ('template' in data) {
    const { template, isLoading, fullWidth } = data;

    return (
      <StyledListItem
        data-testid={`promo-card-phone-${currentIndex}`}
        fullWidth={fullWidth}
        debug={debug}
      >
        <PromoCardSwitch template={template} isLoading={isLoading} />
        {debug && !environment.isProduction && (
          <CardIndexDebugger
            currentIndex={currentIndex}
            wantedIndex={wantedIndex}
          />
        )}
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      debug={debug}
      ref={lastCardRef}
      data-testid={lastCardRef ? 'intersect-element' : undefined}
    >
      <ProductCard auction={data} variant={ProductCardVariant.Regular} />
      {debug && !environment.isProduction && (
        <CardIndexDebugger
          currentIndex={currentIndex}
          wantedIndex={wantedIndex}
        />
      )}
    </StyledListItem>
  );
};
