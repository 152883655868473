import { Chip } from '@kvdbil/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  FilterSearchParamKey,
  IntervalSearchParamLabelKey,
  MultiselectSearchParamKey,
  OtherSearchParamKey,
  ToggleSearchParamKey
} from '~/App/shared/types/FilterSearchParam';
import { useTranslation } from '~/Locale';
import { MinMaxIntervalKey } from '~/config/constants';
import {
  isIntervalSearchParamKey,
  getKeyWithoutFromOrTo,
  isMultiselectSearchParamKey,
  isOtherSearchParams,
  isToggleSearchParamKey,
  isTermsSearchParamKey,
  isIntervalSearchParamLabelKey
} from '~/helpers/filterSearchParams';
import {
  translateIntervalValues,
  translatedFilterValue,
  TranslateLabels,
  getFacilityName
} from '~/helpers/filterTranslation';
import { SliderType } from './FilterSelector/types';

const FilterChip = styled(Chip)<{ isLast: boolean }>`
  margin: ${({ isLast }) =>
    isLast ? '0.25rem 1rem 0.25rem 0.25rem' : '0.25rem'};
`;

type Props = {
  searchQuery: string;
  onRemoveChip: (key: FilterSearchParamKey | FilterSearchParamKey[]) => void;
  minMaxIntervalValues: Record<MinMaxIntervalKey, SliderType>;
};

export const FilterChipList = ({
  searchQuery,
  onRemoveChip,
  minMaxIntervalValues
}: Props) => {
  const { t } = useTranslation();

  const searchParams = useMemo(
    () => new URLSearchParams(searchQuery),
    [searchQuery]
  );

  const values = useMemo(() => {
    const multiSelectSearchParams = new Map<
      MultiselectSearchParamKey,
      string[]
    >();
    const otherSearchParams = new Map<OtherSearchParamKey, string>();
    const intervalParams = new Map<IntervalSearchParamLabelKey, SliderType>();
    const toggleSearchParams = new Map<ToggleSearchParamKey, string>();

    const terms = new Set<string>();

    searchParams.forEach((value: string, key: FilterSearchParamKey) => {
      if (isIntervalSearchParamKey(key)) {
        const keyWithoutFromOrTo = getKeyWithoutFromOrTo(key);

        const [isFrom, isTo] = key.endsWith('From')
          ? [true, false]
          : [false, true];

        const [
          from = minMaxIntervalValues[keyWithoutFromOrTo][0],
          to = minMaxIntervalValues[keyWithoutFromOrTo][1]
        ] = intervalParams.get(keyWithoutFromOrTo) ?? [];
        intervalParams.set(keyWithoutFromOrTo, [
          isFrom ? Number(value) : from,
          isTo ? Number(value) : to
        ]);
      } else if (isMultiselectSearchParamKey(key)) {
        multiSelectSearchParams.set(key, value.split(/\s*,\s*/));
      } else if (isOtherSearchParams(key)) {
        otherSearchParams.set(key, value);
      } else if (isToggleSearchParamKey(key)) {
        toggleSearchParams.set(key, value);
      } else if (isTermsSearchParamKey(key)) {
        terms.add(value);
      }
    });

    const intervalValues = Array.from(intervalParams).map(([key, value]) => [
      key,
      translateIntervalValues(t, key, value, minMaxIntervalValues)
    ]);

    const multiselectValues = Array.from(multiSelectSearchParams).map(
      ([key, value]) => {
        // facilities are special, they are not translated
        if (key === 'facilities') {
          return [key, value.map(getFacilityName).join(', ')];
        }

        return [
          key,
          value.map(value => translatedFilterValue(t, value)).join(', ')
        ];
      }
    );
    const otherValues = Array.from(otherSearchParams).map(([key]) => [
      key,
      TranslateLabels(t, key)
    ]);

    const toggleValues = Array.from(toggleSearchParams)
      // we are note showing vehicleType in the chip list anymore...
      .filter(([key]) => key !== 'vehicleType')
      .map(([key, value]) => [key, translatedFilterValue(t, value)]);

    const termsValues = Array.from(terms)
      .toString()
      .replace(/\s{1,}/g, ' ')
      .trim();

    return [
      ...intervalValues,
      ...multiselectValues,
      ...otherValues,
      ...toggleValues,
      ...(termsValues ? [['terms', termsValues]] : [])
    ];
  }, [searchParams, t, minMaxIntervalValues]);

  const handleRemoveChip = (key: FilterSearchParamKey) => {
    if (isIntervalSearchParamLabelKey(key)) {
      const asFromIntervalKey = `${key}From` as IntervalSearchParamLabelKey;
      const asToIntervalKey = `${key}To` as IntervalSearchParamLabelKey;

      onRemoveChip([asFromIntervalKey, asToIntervalKey]);
      return;
    }

    onRemoveChip(key);
  };

  return (
    <>
      {values.map(
        ([key, value]: [FilterSearchParamKey, string], index: number) => (
          <FilterChip
            key={key}
            color="neutralLight"
            isLast={values.length - 1 === index}
            onDelete={() => handleRemoveChip(key)}
            size="small"
          >
            <span>{value ?? null}</span>
          </FilterChip>
        )
      )}
    </>
  );
};
