import React, { useCallback } from 'react';
import { BodyLink } from '@kvdbil/components';
import styled from 'styled-components';
import { OptionType } from '~/App/shared/types/OptionType';

const Container = styled.div`
  display: flex;
`;

interface ContentProps {
  active?: boolean;
}

const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;

  ${props =>
    props.active &&
    `
        background-color: ${props.theme.colors.info.main};
        color: ${props.theme.colors.gray.light6};
    `}
`;

const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconWrapper = styled.span`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface ToggleMobileFilterItemProps {
  option: OptionType;
  onChange: (value: OptionType) => void;
  value: OptionType | null;
}

export const ToggleMobileFilterItem = ({
  option,
  onChange,
  value
}: ToggleMobileFilterItemProps) => {
  const checked = Boolean(value?.value === option.value);

  const handleChange = useCallback(() => {
    onChange(option);
  }, [onChange, option]);

  return (
    <Container>
      <Content onClick={handleChange} active={checked}>
        <StyledOptionWrapper>
          {option.icon && <StyledIconWrapper>{option.icon}</StyledIconWrapper>}
          <BodyLink>{option.label}</BodyLink>
        </StyledOptionWrapper>
      </Content>
    </Container>
  );
};
