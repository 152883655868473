import React, { FunctionComponent } from 'react';
import { DropdownIndicatorProps as SelectDropdownIndicatorProps } from 'react-select';
import { theme, ShowMoreIcon } from '@kvdbil/components';
import styled from 'styled-components';

const DEFAULT_TRANSITION_MS = '100ms';

type DropdownArrowProps = {
  $isMenuOpen: boolean;
  $isFocused: boolean;
  $isDisabled: boolean;
};

export const DropdownArrow = styled(ShowMoreIcon)<DropdownArrowProps>`
  ${props => props.$isFocused && `color: ${theme.colors.text.dark}`}
  ${props => props.$isDisabled && `color: ${theme.colors.gray.dark1}`}

  transition: transform ${DEFAULT_TRANSITION_MS};

  transform: ${props =>
    props.$isMenuOpen ? 'rotate(-180deg)' : 'rotate(0deg)'};

  transform-origin: 50% 60%;
`;

export interface DropdownIndicatorProps extends SelectDropdownIndicatorProps {
  isFocused: boolean;
  isDisabled: boolean;
}

export const DropdownIndicator: FunctionComponent<
  DropdownIndicatorProps | unknown
> = ({
  selectProps: { menuIsOpen = false },
  isFocused = false,
  isDisabled
}: DropdownIndicatorProps) => {
  return (
    <DropdownArrow
      width="1rem"
      height="1rem"
      $isMenuOpen={menuIsOpen}
      $isDisabled={isDisabled}
      $isFocused={isFocused}
    />
  );
};
