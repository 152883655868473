import { BaseFilterSelectPorps } from '../types';
import { OptionProps, GroupBase } from 'react-select';
import { theme } from '@kvdbil/components';
import { DefaultOptionType } from './BaseFilterSelect';

export function useBaseFilterSelect<
  Option = DefaultOptionType,
  IsMulti extends boolean = false
>(
  props: Partial<BaseFilterSelectPorps<Option, IsMulti>>
): Partial<BaseFilterSelectPorps<Option, IsMulti>> & {
  getOptionColor: (props: Partial<OptionProps<Option, IsMulti>>) => string;
} {
  const getOptionColor = ({
    isSelected,
    isFocused,
    isDisabled
  }: Partial<OptionProps<Option, IsMulti, GroupBase<Option>>>) => {
    if (isSelected) {
      return theme.colors[props.color ?? 'info'].main;
    }

    if (isDisabled) {
      return theme.colors.background.light;
    }

    if (isFocused) {
      return theme.colors.background.gray;
    }

    return theme.colors.background.light;
  };

  return {
    getOptionColor,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    controlShouldRenderValue: false,
    isSearchable: false,
    isClearable: true,
    openMenuOnFocus: false,
    ...props
  };
}
