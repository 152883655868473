import React from 'react';

import { ToggleFilterSelect } from '../ToggleFilter/ToggleFilterSelect';
import { ToggleMobileFilterSelect } from '../ToggleFilter/ToggleMobileFilterSelect';
import { MultiFilterSelect } from '../MultiFilter/MultiFilterSelect';
import { MultiMobileFilterSelect } from '../MultiFilter/MultiMobileFilterSelect';
import { SliderFilterSelect } from '../SliderFilter/SliderFilterSelect';
import { SliderMobileFilterSelect } from '../SliderFilter/SliderMobileFilterSelect';

import {
  FilterSelectSwitchProps,
  MultiFilterSelectProps,
  SliderFilterSelectProps,
  ToggleFilterSelectProps
} from '../types';

export function FilterSwitch({
  useFilterPropsHook,
  ...props
}: Omit<FilterSelectSwitchProps, 'onChange'>) {
  const hookProps = useFilterPropsHook(props);

  switch (hookProps.type) {
    case 'ToggleFilterSelect':
      return (
        <ToggleFilterSelect
          {...({ ...props, ...hookProps } as ToggleFilterSelectProps)}
        />
      );

    case 'MultiFilterSelect':
      return (
        <MultiFilterSelect
          {...({ ...props, ...hookProps } as MultiFilterSelectProps)}
        />
      );

    case 'SliderFilterSelect':
      return (
        <SliderFilterSelect
          {...({ ...props, ...hookProps } as SliderFilterSelectProps)}
        />
      );

    default:
      return null;
  }
}

export function MobileFilterSwitch({
  useFilterPropsHook,
  ...props
}: Omit<FilterSelectSwitchProps, 'onChange'>) {
  const hookProps = useFilterPropsHook(props);

  switch (hookProps.type) {
    case 'ToggleFilterSelect':
      return (
        <ToggleMobileFilterSelect
          {...({ ...props, ...hookProps } as ToggleFilterSelectProps)}
        />
      );

    case 'MultiFilterSelect':
      return (
        <MultiMobileFilterSelect
          {...({ ...props, ...hookProps } as MultiFilterSelectProps)}
        />
      );

    case 'SliderFilterSelect':
      return (
        <SliderMobileFilterSelect
          {...({ ...props, ...hookProps } as SliderFilterSelectProps)}
        />
      );

    default:
      return null;
  }
}
