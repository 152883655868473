import React from 'react';
import { useToggleFilterSelect } from './useToggleFilterSelect';
import CollapseFilterSelect from '../Shared/CollapseFilterSelect';
import { ToggleMobileFilterItem } from './ToggleMobileFilterItem';
import { isOptionsType } from '../helper';
import { ToggleFilterSelectProps } from '../types';

export const ToggleMobileFilterSelect = (props: ToggleFilterSelectProps) => {
  const { options, placeholder } = props;

  const { value, isOpen, setIsOpen, handleChange } =
    useToggleFilterSelect(props);

  return (
    <CollapseFilterSelect
      isOpen={isOpen}
      label={placeholder ?? ''}
      onClick={() => setIsOpen(pre => !pre)}
    >
      {options &&
        isOptionsType(options) &&
        options.map((option, index) => (
          <div key={index}>
            <ToggleMobileFilterItem
              key={index}
              onChange={handleChange}
              option={option}
              value={value}
            />
          </div>
        ))}
    </CollapseFilterSelect>
  );
};
