import { BodyLink, ShowMoreIcon, mq } from '@kvdbil/components';
import React from 'react';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text.dark};

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.light3};
  padding: 0.25rem 0.5rem;
`;

const SelectLabel = styled(BodyLink)<{ isOpened: boolean }>`
  margin: 0.75rem 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    transition: transform 350ms ease-in-out;

    ${mq('tablet')} {
      margin-right: 0.5rem;
    }

    ${({ isOpened }) => isOpened && `transform: rotateZ(-180deg);`}
  }
`;

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  label: string;
  onClick: () => void;
}

const CollapseFilterSelect = ({ children, label, onClick, isOpen }: Props) => {
  return (
    <Container>
      <SelectLabel isOpened={isOpen} onClick={onClick}>
        {label}
        <ShowMoreIcon />
      </SelectLabel>

      <Collapse isOpened={isOpen}>{children}</Collapse>
    </Container>
  );
};

export default CollapseFilterSelect;
