import { useCallback } from 'react';

import { ToggleFilterSelectProps, OptionType } from '../types';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import { AuctionType } from '~/App/shared/types/AuctionType';
import { Nullable } from '~/App/shared/types/Nullable';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';

export const useBuyMethodFilterSelect = (
  props: ToggleFilterSelectProps
): ToggleFilterSelectProps => {
  const { name, options } = props;

  const activeValue = useParamFromQuery(name) as Nullable<AuctionType>;

  const [, setSearchParam] = useSearchParams();

  const handleChange = useCallback(
    (option: OptionType, name: string) => {
      setSearchParam(name, option?.value ?? '');
    },
    [setSearchParam]
  );

  return {
    ...props,
    options: options,
    onChange: handleChange,
    activeValue: activeValue ?? ''
  };
};
