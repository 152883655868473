import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Button, CaptionLink, mq } from '@kvdbil/components';

import { ColorField } from '~/config/generated/graphql';
import { Color, Size } from '@kvdbil/components/types/Types';
import Link from '~/App/shared/components/Link';
import {
  isButtonRecord,
  isTextLinkRecord
} from '~/App/views/FilterPage/cmsDataHandlers';
import { PromoCardBaseTemplate } from '../../interfaces/PromoSettingCMSData';
import { Nullable } from '../../types/Nullable';
import { CardContent } from './CardContent';
import { useGA4User } from '../../hooks/useGA4User';
import { trackGA4FilterContentCard } from '~/helpers/client/ga4TrackEvent';
import { animateScroll } from 'react-scroll';

interface ContainerProps {
  $backgroundColor: Nullable<ColorField>;
  $color: Nullable<ColorField>;
}

const AdditionalCardContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 26rem;
  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor?.hex ?? theme.colors.background.light};
  padding: 2rem 1rem;
  box-shadow: ${({ theme }) => theme.elevations.elevation2};
  text-align: center;

  border-radius: 0.5rem;

  ${mq('tablet')} {
    padding: 1rem;
  }

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color.hex};
    `};

  & > p {
    /* needs css from caption */
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const CardLink = ({ link }: { link: PromoCardBaseTemplate['link'] }) => {
  if (!link) {
    return null;
  }

  if (isButtonRecord(link)) {
    return (
      <StyledLink to={link.url}>
        <Button color={link.color as Color} size={link.size as Size} fullWidth>
          {link.text}
        </Button>
      </StyledLink>
    );
  }

  if (isTextLinkRecord(link)) {
    return (
      <Link to={link.url}>
        <CaptionLink>{link.text}</CaptionLink>
      </Link>
    );
  }

  return null;
};

export type PromoCardAdditionalProps = PromoCardBaseTemplate;

export const PromoCardAdditional = ({
  content,
  backgroundColor,
  textColor,
  link,
  ga4EventIdentifier
}: PromoCardAdditionalProps) => {
  const ga4User = useGA4User();

  const handleOnClick = useCallback(() => {
    trackGA4FilterContentCard({ card_type: ga4EventIdentifier }, ga4User);
    animateScroll.scrollToTop();
  }, [ga4EventIdentifier, ga4User]);

  return (
    <AdditionalCardContainer
      $backgroundColor={backgroundColor}
      $color={textColor}
      data-testid="promo-card-additional"
      onClick={handleOnClick}
    >
      {content && <CardContent data={content} />}

      <CardLink link={link} />
    </AdditionalCardContainer>
  );
};

export default PromoCardAdditional;
