import { useMemo } from 'react';
import Auction from '~/App/shared/interfaces/Auction';
import { PromoSettingCMSData } from '~/App/shared/interfaces/PromoSettingCMSData';
import { recalculateIndex } from './CardIndexDebugger';
import { CardListData, Options } from './interfaces';
import { createCardLists, createPromoCardLists } from './helpers';
import { ObjectListDevice } from '../ObjectListContainer';

type UseCardListProps = {
  auctions: Auction[];
  options: Options;
  promoCards?: PromoSettingCMSData['promoCards'];
  debug?: boolean;
  device: ObjectListDevice;
  withNoPromoCards: boolean;
};

export const useCardList = ({
  auctions,
  options,
  promoCards = [],
  debug = false,
  device,
  withNoPromoCards
}: UseCardListProps) => {
  const cards = useMemo(() => {
    // create a list of all product cards
    const cards: CardListData[] = auctions.map((auction, index) => ({
      index,
      data: auction
    }));

    if (withNoPromoCards) {
      return cards;
    }

    // I need to create a list of all promo components for each breakpoint
    const { mobilePromoCards, tabletPromoCards, desktopPromoCards } =
      createPromoCardLists(promoCards, options);

    switch (device) {
      case 'mobile':
        const mobileCards = createCardLists(cards, mobilePromoCards);
        return debug ? mobileCards.map(recalculateIndex) : mobileCards;
      case 'tablet':
        const tabletCards = createCardLists(cards, tabletPromoCards);
        return debug ? tabletCards.map(recalculateIndex) : tabletCards;
      case 'desktop':
        const desktopCards = createCardLists(cards, desktopPromoCards);
        return debug ? desktopCards.map(recalculateIndex) : desktopCards;
    }
  }, [auctions, options, promoCards, debug, withNoPromoCards, device]);

  return { cards };
};
